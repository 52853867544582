/* Header
======================================= */
import React from "react";
import { Text } from "../../components/ui";
import { ChatFlowTags } from "../../constants";
import { HeaderIcon } from "../../components/ui/Icons";

import styles from "./index.module.scss";
import theme from "../../theme/default";

interface Props {
  chatInfo: ChatInfo;
  endChat: () => void;
}
const Header: React.FC<Props> = (props) => {
  const { chatInfo, endChat } = props;
  const { locations, chatFlowTags, date, time } = chatInfo;
  const apptRequestChat = chatFlowTags.includes(ChatFlowTags.APPOINTMENT_REQUEST);
  const previsitChat = chatFlowTags.includes(ChatFlowTags.PREVISIT);
  const isAppointmentTimeHidden = chatFlowTags.includes(ChatFlowTags.HIDE_APPOINTMENT_TIMES);

  const hasTime = !!time;
  const hasDate = !!date;

  const locationName = locations?.length === 1 ? locations[0].locationName : "";

  const { appointmentRequestHeaderTitle } = chatInfo?.appearanceSettings?.settingValue || {};

  return (
    <div className={styles.Header}>
      <div className={styles.inside}>
        <div className={styles.clinic}>
          <div style={{ marginRight: "16px" }}>
            <HeaderIcon size={24} color={theme.colors.icon.primary} />
          </div>
          <div className={styles.info}>
            {apptRequestChat && (
              <>
                {appointmentRequestHeaderTitle !== undefined && (
                  <Text variant="small" customStyle={{ marginBottom: "1px" }} bold>
                    {appointmentRequestHeaderTitle}
                  </Text>
                )}
                {appointmentRequestHeaderTitle === undefined && (
                  <>
                    <Text variant="small" customStyle={{ marginBottom: "1px" }} bold>
                      {theme.apptReqHeaderText}
                    </Text>
                    <Text variant="small" customStyle={{ marginRight: "24px" }}>
                      {theme.apptReqHeaderSubtext}
                    </Text>
                  </>
                )}
              </>
            )}
            {previsitChat && (
              <>
                <Text variant="small" customStyle={{ marginBottom: "1px" }} bold>
                  {locationName}
                </Text>
                {!isAppointmentTimeHidden && hasTime && hasDate && (
                  <Text variant="small">{`${date} at ${time}`}</Text>
                )}
                {!isAppointmentTimeHidden && hasTime && !hasDate && (
                  <Text variant="small">{`${time}`}</Text>
                )}
                {!isAppointmentTimeHidden && !hasTime && hasDate && (
                  <Text variant="small">{`${date}`}</Text>
                )}
              </>
            )}
          </div>
        </div>
        <button type="button" className={styles.backIcon} onClick={endChat}>
          <Text id="endChat" variant="xsmall">
            End Chat
          </Text>
        </button>
      </div>
    </div>
  );
};
Header.displayName = "Header";
export default Header;
