import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const Error = ({ size = 24, color = "currentColor" }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0ZM7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1ZM7 2.91667C6.67783 2.91667 6.41667 3.17783 6.41667 3.5V8.16667C6.41667 8.48883 6.67783 8.75 7 8.75C7.32217 8.75 7.58333 8.48883 7.58333 8.16667V3.5C7.58333 3.17783 7.32217 2.91667 7 2.91667ZM6.58752 10.0875C6.35971 10.3153 6.35971 10.6847 6.58752 10.9125C6.81533 11.1403 7.18467 11.1403 7.41248 10.9125C7.64028 10.6847 7.64028 10.3153 7.41248 10.0875C7.18467 9.85972 6.81533 9.85972 6.58752 10.0875Z"
        fill={color || "#A31D18"}
      />
    </svg>
  );
};

export default Error;
