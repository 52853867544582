/* PHOTO_UPLOAD Card
======================================= */

import React from "react";
import {
  TextCard,
  Card,
  ImageContain,
  Button,
  UploadInput,
  FieldError,
  Loader,
  Text,
  SkipButton
} from "..";
import moment from "moment";
import { ChevronDown, ChevronRight, DeleteIcon, Logo } from "../Icons";
import { BucketNameSuffixes, ChatCardTypes } from "../../../constants";
import { getSignedUrl, uploadFiles, createPatientUpload, destroyPatientUpload } from "../../../lib";

import styles from "./index.module.scss";
import { marginTop } from "styled-system";

const acceptedFileTypes = ["image/jpeg", "image/png", "application/pdf"];
interface PhotoUploadProps {
  msg: Message;
  index: number;
  token: string;
  isCurrentMessage: boolean;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  conversationId: number;
}

interface PhotoUploadState {
  uploading: boolean;
  files: FileWithDataURL[];
  error: boolean;
  errorMsg: string;
}

interface FileWithDataURL {
  file: File | null;
  dataURL: string | ArrayBuffer | null;
  name: string | null;
  expanded?: boolean;
  secureMessageItemId?: number;
}

class PhotoUploadCard extends React.Component<PhotoUploadProps, PhotoUploadState> {
  constructor(props: PhotoUploadProps) {
    super(props);

    this.state = {
      uploading: false,
      files: [],
      error: false,
      errorMsg: ""
    };
  }

  onDrop = async (e: React.FormEvent<Element>) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    // https://stackoverflow.com/questions/57002671/javascript-input-event-fire-twice
    if (!e.target) return;
    e.target = null as unknown as EventTarget;

    const { files } = this.state;
    const { token, conversationId } = this.props;
    this.setState({ uploading: true });
    const file: File = (target.files as FileList)[0];

    if (!acceptedFileTypes.includes(file.type)) {
      this.setState({
        uploading: false,
        error: true,
        errorMsg: "File must be type jpeg, png or pdf."
      });
    } else {
      let dataURL;
      const reader = new FileReader();

      reader.onload = async () => {
        try {
          dataURL = reader.result;
          const {
            url,
            error,
            filename: fileName
          } = await getSignedUrl({
            bucketName: BucketNameSuffixes.PATIENT_UPLOADS,
            fileExt: file.type.split("/")[1],
            fileNamePrefix: "to-convert-",
            token,
            conversationId
          });
          if (error) {
            this.setState({
              uploading: false,
              error: true,
              errorMsg: "Failed to upload file. Please try again."
            });
          }

          await uploadFiles(url, dataURL);
          const { secureMessageItemId } = await createPatientUpload(fileName, conversationId);
          this.setState({
            error: false,
            uploading: false,
            files: [
              ...files,
              { file, dataURL, name: file.name, secureMessageItemId, expanded: true }
            ]
          });

          const scrollContainer = document.getElementById("scrollContainer");
          setTimeout(() => {
            scrollContainer?.scrollTo({
              top: scrollContainer.scrollHeight,
              behavior: "smooth"
            });
          });
        } catch (error) {
          this.setState({
            uploading: false,
            error: true,
            errorMsg: "Failed to upload file. Please try again."
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  submit = async () => {
    const { fetchNextMessage } = this.props;
    const { files } = this.state;
    await fetchNextMessage({
      lastReceivedMessageId: null,
      userResponse: String(files.length),
      userResponseType: ChatCardTypes.PHOTO_UPLOAD,
      chatFlowId: null,
      showUserResponse: true,
      createdAt: moment().toDate()
    });
    this.setState({ uploading: false, error: false, errorMsg: "" });
  };

  renderFileDrawer = (file: FileWithDataURL, previewIndex: number, uploading: boolean) => {
    const src = typeof file.dataURL === "string" ? file.dataURL : "";
    const { expanded, name } = file;
    let fileNameToDisplay = name;
    if (!fileNameToDisplay) fileNameToDisplay = `File ${previewIndex + 1}`;
    // else if (fileNameToDisplay.length > 20)
    //   fileNameToDisplay = `${fileNameToDisplay.substring(0, 20)}...`;

    return (
      <Card customStyle={{ marginBottom: "16px" }} key={`file-upload-preview-${file.name}`}>
        <div className={styles.PreviewHeader}>
          <button
            type="button"
            className={styles.PreviewTitle}
            onClick={() => this.toggleExpand(previewIndex)}
          >
            {expanded ? <ChevronDown /> : <ChevronRight />}
            <Text variant="medium" textAlign="left">
              {fileNameToDisplay}
            </Text>
          </button>

          <button
            type="button"
            onClick={() => this.destroyUpload(previewIndex)}
            disabled={uploading}
          >
            <DeleteIcon />
          </button>
        </div>
        {expanded && <ImageContain src={src} size="contain" customStyle={{ marginTop: "16px" }} />}
      </Card>
    );
  };

  toggleExpand = (previewIndex: number) => {
    const { files } = this.state;
    const newFiles = files;
    newFiles[previewIndex].expanded = !files[previewIndex].expanded;
    this.setState({ files: newFiles });
  };

  destroyUpload = async (previewIndex: number) => {
    this.setState({ uploading: true });
    const { files } = this.state;
    const { conversationId } = this.props;

    const { secureMessageItemId } = files[previewIndex];
    if (secureMessageItemId) {
      try {
        await destroyPatientUpload(secureMessageItemId, conversationId);
      } catch (error) {
        this.setState({
          uploading: false,
          error: true,
          errorMsg: "Failed to delete file. Please try again."
        });
        return;
      }
    }
    const newFiles = files;
    newFiles.splice(previewIndex, 1);
    this.setState({ uploading: false, files: newFiles, error: false, errorMsg: "" });
  };

  render() {
    const { msg, index, isCurrentMessage } = this.props;
    const { files, error, errorMsg, uploading } = this.state;
    const { payloadContent } = msg;
    const payloadOptions = msg?.payloadOptions as PhotoUploadCardPayloadOptions;
    const skippable = payloadOptions?.skippable || false;
    const maxUploads = payloadOptions?.maxUploads || 10;

    let promptText = payloadOptions?.uploadText || "Choose file";
    if (files.length >= maxUploads) promptText = "Maximum uploads reached";
    else if (files.length > 1) promptText = "Add another file";

    return (
      <div className={styles.PhotoUploadCard}>
        <TextCard
          msg={msg}
          index={index}
          avatar={<Logo size={18} />}
          customStyle={{ marginBottom: "16px" }}
        >
          <>{payloadContent}</>
        </TextCard>
        {isCurrentMessage && (
          <>
            {files.map((file, idx) => this.renderFileDrawer(file, idx, uploading))}
            {!uploading && (
              <>
                <UploadInput
                  id={`uploadInput-${index}`}
                  customStyle={{ marginBottom: "16px" }}
                  onChange={this.onDrop}
                  disabled={files.length >= maxUploads}
                  promptText={promptText}
                />
                {error && (
                  <FieldError customStyle={{ marginBottom: "20px" }}>{errorMsg}</FieldError>
                )}
                <Button
                  id="photoSubmitBtn"
                  disableOnClick
                  onClick={this.submit}
                  disabled={files.length < 1}
                >
                  Upload
                </Button>
                {skippable && files.length === 0 && <SkipButton index={index} message={msg} />}
              </>
            )}
            {uploading && <Loader center />}
          </>
        )}
      </div>
    );
  }
}

export default PhotoUploadCard;
