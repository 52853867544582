/* eslint-disable react/jsx-no-useless-fragment */
/* Choice
======================================= */

import React from "react";
import cx from "classnames";

import Text from "../Text/Text";
import { MultipleOn, MultipleOff, CircleCheck, Circle } from "../Icons";
import theme from "../../../theme/default";
import styles from "./index.module.scss";

/* Props
--------------------------------------- */

interface Props {
  // text: string;
  option: string | { text: string; value: string };
  text: string;
  active: boolean;
  children?: React.ReactNode;
  selectOption: (
    optionSelectedToDisplay: string,
    optionSelected: string | { text: string; value: string }
  ) => void;
  multiple?: boolean;
  disabled?: boolean;
  detailed?: boolean;
  value?: string;
}

/* Component
--------------------------------------- */

const DetailedChoice: React.FC<Props> = (props) => {
  const { option, value, disabled, multiple, active, detailed, children, selectOption } = props;
  const text = typeof option === "object" ? option.text : option;

  return (
    <button
      value={value}
      disabled={disabled}
      type="button"
      className={cx(styles.Choice, styles.DetailedChoice)}
      onClick={() => selectOption(text, option)}
    >
      <span style={{ marginRight: "10px" }}>
        {multiple ? (
          <>
            {active ? (
              <MultipleOn color={theme.colors.primary.blue} />
            ) : (
              <MultipleOff color={theme.colors.grey[2]} />
            )}
          </>
        ) : (
          <>
            {active ? (
              <CircleCheck color={theme.colors.primary.blue} />
            ) : (
              <Circle color={theme.colors.grey[2]} />
            )}
          </>
        )}
      </span>
      <div>
        {text && (
          <Text as="span" bold={detailed}>
            {text}
          </Text>
        )}
        {children && children}
      </div>
    </button>
  );
};

DetailedChoice.displayName = "DetailedChoice";

export default DetailedChoice;
