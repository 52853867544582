import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import qs from "query-string";

import {
  clearChatMessages as clearChatMessagesAction,
  fetchChatInfo as fetchChatInfoAction,
  initConversationHub as initConversationHubAction
} from "../../actions";

import useCountdown from "../../hooks/useCountdown";

type PropsType = {
  children: React.ReactChild;
  messages?: Message[];
  clearChatMessages?: () => void;
  initConversationHub?: (params: { shortCode: string }) => void;
  chatInfo?: ChatInfo;
};

type RouteParams = {
  shortCode?: string;
};

const DEFAULT_TIMEOUT_MILLISECONDS: number = 1000 * 60 * 60; // 1 hour

type SessionManagerContextType = {
  sessionToken: string;
  endChatSession: () => void;
};

export const initialSessionManagerContext: SessionManagerContextType = {
  sessionToken: "",
  endChatSession: () => console.error("Session manager not initialized")
};

export const ChatSessionManagerContext = React.createContext(initialSessionManagerContext);

const ChatSessionManager = ({
  children,
  messages,
  clearChatMessages,
  initConversationHub,
  chatInfo
}: PropsType): JSX.Element => {
  const params = useParams<RouteParams>();
  const { search } = useLocation();
  const parsedQueryStrings = qs.parse(search);

  const [sessionToken, setSessionToken] = useState("");
  useEffect(() => {
    const { shortCode = "" } = params;

    if (initConversationHub) initConversationHub({ shortCode, ...parsedQueryStrings });
  }, [params?.shortCode]);

  useEffect(() => {
    if (chatInfo?.initialized === true) {
      setSessionToken(sessionStorage.getItem("token") || "");
    } else {
      sessionStorage.removeItem("token");
    }
  }, [chatInfo?.initialized]);

  const endChatSession = () => {
    const { shortCode = "" } = params;
    if (shortCode) {
      if (initConversationHub) initConversationHub({ shortCode, ...parsedQueryStrings });
    }
    if (clearChatMessages) clearChatMessages();
  };

  const onInactiveTimeout = useCallback(() => {
    endChatSession();
  }, []);

  const { resetCountdown } = useCountdown({
    onTimeout: onInactiveTimeout,
    timeoutMilliseconds: DEFAULT_TIMEOUT_MILLISECONDS
  });

  useEffect(() => {
    resetCountdown();
  }, [messages?.length]);

  const sessionContext = useMemo(
    () => ({ sessionToken, endChatSession }),
    [endChatSession, sessionToken]
  );

  return (
    <ChatSessionManagerContext.Provider value={sessionContext}>
      {chatInfo?.initialized && children}
    </ChatSessionManagerContext.Provider>
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {
    messages: state.chatMessages.messages,
    chatInfo: state.chatInfo
  };
};

export default connect(mapStateToProps, {
  clearChatMessages: clearChatMessagesAction,
  fetchChatInfo: fetchChatInfoAction,
  initConversationHub: initConversationHubAction
})(ChatSessionManager);
