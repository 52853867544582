import React from "react";
import cx from "classnames";

import styles from "./index.module.scss";

type Props = {
  boxShadow?: boolean;
  children?: React.ReactNode;
  className?: string;
  customStyle?: React.CSSProperties;
};

const Card: React.FC<Props> = ({ boxShadow, children, className, customStyle = {} }) => {
  const style: React.CSSProperties = {
    boxShadow: boxShadow ? "0px 1px 4px rgba(0, 0, 0, 0.0779338)" : "",
    ...customStyle
  };

  return (
    // do not remove card-ui style
    <div id="card" className={cx(styles.Card, className, "card-ui")} style={style}>
      {children}
    </div>
  );
};

Card.displayName = "Card";

export default Card;
