/* Upload
======================================= */
import React from "react";

import { Text } from "../../../ui";
import { UploadIcon } from "../../Icons";

import styles from "./index.module.scss";
/* Props
--------------------------------------- */

interface Props {
  id?: string;
  promptText?: string;
  disabled?: boolean;
  customStyle?: React.CSSProperties;
  onChange: (event: React.FormEvent) => void;
}

/* Component
--------------------------------------- */

const UploadInput: React.FC<Props> = ({ id, promptText, disabled, customStyle, onChange }) => {
  return (
    <div className={styles.Container} id={id} style={customStyle}>
      <label>
        {!disabled && (
          <div style={{ marginRight: "8px" }}>
            <UploadIcon />
          </div>
        )}
        <Text textColor={disabled ? "error.text" : "primary.blue"}>
          {promptText || "Choose File"}
        </Text>
        <input
          type="file"
          accept="image/jpeg, image/png, application/pdf, capture=camera"
          disabled={disabled !== undefined ? disabled : false}
          onChange={(e) => onChange(e)}
        />
      </label>
    </div>
  );
};

UploadInput.displayName = "UploadInput";

export default UploadInput;
