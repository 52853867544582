/* Steps
======================================= */

import React, { createContext } from "react";

export type VariantTypes = "vertical" | "horizontal" | "bullet" | "numbered" | undefined;
export const VariantContext = createContext<VariantTypes>(undefined);

interface Props {
  variant?: VariantTypes;
  children?: React.ReactNode;
}

const Steps: React.FC<Props> = ({ variant = "vertical", children }) => {
  return (
    <VariantContext.Provider value={variant}>
      <div>{children}</div>
    </VariantContext.Provider>
  );
};

Steps.displayName = "Step";

export default Steps;
