import React from "react";

import { Box } from "../../../components/layout";
import { Card, Text } from "../../../components/ui";
import JsonContentRenderEngine from "../../../components/JsonContentRenderEngine";
import { JsonContent } from "../../../types";

interface Props {
  location: {
    id: number;
    locationName: string | null;
    locationInformation: JsonContent | null;
    phoneNumber: string | null;
    address: string | null;
  };
}

const MoreDetailsCard: React.FC<Props> = ({ location }: Props) => {
  const address = location?.address?.replace(/,/gim, "").trim() || null; // remove commas
  const phoneNumber = location?.phoneNumber || null; // Remove all non-numeric characters

  if (!address && !phoneNumber && !location?.locationInformation) {
    return null;
  }

  return (
    <div>
      <Card customStyle={{ padding: "16px 16px 4px", marginBottom: "24px" }} boxShadow>
        <Box customStyle={{ width: "100%", marginBottom: "16px" }}>
          <Text variant="titleSmall" bold customStyle={{ marginBottom: "8px" }}>
            More Details
          </Text>
        </Box>
        {/* For clinics that have no location number do not show the contact box. */}
        {phoneNumber && (
          <Box customStyle={{ width: "100%", marginBottom: "16px" }}>
            <Text variant="meta">Contact</Text>
            <Text>{phoneNumber}</Text>
          </Box>
        )}
        {/* For clinics that have no address do not show the address box. Cases exist where mikata is not provided the appointments location data */}
        {address && (
          <Box customStyle={{ width: "100%", marginBottom: "16px" }}>
            <Text variant="meta">Address</Text>
            <Text>
              {location.locationName}
              <br />
              {address}
            </Text>
          </Box>
        )}
        {location.locationInformation && (
          <Box customStyle={{ width: "100%", marginBottom: "16px" }}>
            <JsonContentRenderEngine
              content={location.locationInformation}
              placeholderContext={{ locationNumber: phoneNumber || "" }}
              contentBoxShadow={false}
              contentPadding="0px"
              showInfoModalButton={false}
            />
          </Box>
        )}
      </Card>
    </div>
  );
};

export default MoreDetailsCard;
