import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const MultipleOn = ({ size = 24, color }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7188 1.28957C20.9062 1.49277 21 1.72724 21 1.99297C21 2.2587 20.9062 2.49316 20.7188 2.69637L10.7109 12.7081C10.6016 12.8019 10.4805 12.8722 10.3477 12.9191C10.2148 12.966 10.1016 12.9894 10.0078 12.9894C9.91406 12.9894 9.80078 12.966 9.66797 12.9191C9.53516 12.8722 9.41406 12.8019 9.30469 12.7081L6.30469 9.70692C6.10156 9.50371 6 9.26925 6 9.00352C6 8.73779 6.10156 8.50332 6.30469 8.30012C6.50781 8.09691 6.74219 7.99531 7.00781 7.99531C7.27344 7.99531 7.50781 8.09691 7.71094 8.30012L10.0078 10.5979L19.3125 1.28957C19.5156 1.08636 19.75 0.98476 20.0156 0.98476C20.2813 0.98476 20.5156 1.08636 20.7188 1.28957ZM19.0078 9.00352C19.3047 9.00352 19.5469 9.09339 19.7344 9.27315C19.9219 9.45291 20.0156 9.69128 20.0156 9.98828V16.9988C20.0156 17.8429 19.7266 18.5541 19.1484 19.1325C18.5703 19.7108 17.8594 20 17.0156 20H3C2.15625 20 1.44532 19.7108 0.867188 19.1325C0.28906 18.5541 0 17.8429 0 16.9988V3.00117C0 2.14146 0.28906 1.42634 0.867188 0.855803C1.44532 0.285265 2.15625 0 3 0H14.0156C14.3125 0 14.5508 0.0898779 14.7305 0.269637C14.9102 0.449395 15 0.687767 15 0.98476C15 1.29738 14.9102 1.54357 14.7305 1.72333C14.5508 1.90309 14.3125 1.99297 14.0156 1.99297H3C2.70312 1.99297 2.46484 2.08284 2.28516 2.2626C2.10547 2.44236 2.01562 2.68855 2.01562 3.00117V16.9988C2.01562 17.2958 2.10547 17.5381 2.28516 17.7257C2.46484 17.9132 2.70312 18.007 3 18.007H17.0156C17.3125 18.007 17.5508 17.9132 17.7305 17.7257C17.9102 17.5381 18 17.2958 18 16.9988V9.98828C18 9.69128 18.0938 9.45291 18.2812 9.27315C18.4688 9.09339 18.7109 9.00352 19.0078 9.00352Z"
        fill={color || "#4072DE"}
      />
    </svg>
  );
};

export default MultipleOn;
