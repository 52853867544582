import React from "react";

type PropsType = {
  size?: number;
};

const Logo = ({ size = 20 }: PropsType) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.707 1.293l4 4a1 1 0 010 1.414l-10 10A1 1 0 0112 17H8a1 1 0 01-1-1v-4a1 1 0 01.293-.707l10-10a1 1 0 011.414 0zM19 14.66V20a1 1 0 01-1 1H4a1 1 0 01-1-1V6a1 1 0 011-1h5.34a1 1 0 100-2H4a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3v-5.34a1 1 0 10-2 0zM9 15v-2.586l9-9L20.586 6l-9 9H9z"
        fill="#494B50"
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.707 1.293l4 4a1 1 0 010 1.414l-10 10A1 1 0 0112 17H8a1 1 0 01-1-1v-4a1 1 0 01.293-.707l10-10a1 1 0 011.414 0zM19 14.66V20a1 1 0 01-1 1H4a1 1 0 01-1-1V6a1 1 0 011-1h5.34a1 1 0 100-2H4a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3v-5.34a1 1 0 10-2 0zM9 15v-2.586l9-9L20.586 6l-9 9H9z"
          fill="#fff"
        />
      </mask>
    </svg>
  );
};

export default Logo;
