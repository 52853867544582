/* Viewport
======================================= */

import React from "react";
import styles from "./index.module.scss";

const Viewport: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // markup

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Content}>{children}</div>
    </div>
  );
};

Viewport.displayName = "Viewport";

export default Viewport;
