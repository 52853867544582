/* Input Field
======================================= */

import React from "react";
import Select from "react-select";

import { Text } from "../..";
import { styles as selectStyes } from "./styles";

export type SelectFieldOption = { label: string; value: string };

/* Style
--------------------------------------- */
const style: React.CSSProperties = {
  marginBottom: "24px"
};

/* Props
--------------------------------------- */

interface Props {
  id?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  value: SelectFieldOption | null;
  options: Array<SelectFieldOption>;
  disabled?: boolean;
  onChange: (value: any) => void;
}

/* Component
--------------------------------------- */

const SelectInput: React.FC<Props> = ({
  label,
  options,
  placeholder,
  disabled,
  value,
  onChange,
  ...otherProps
}) => {
  const onChangeHandler = (selection: any): void => {
    if (onChange) {
      onChange(selection);
    }
  };

  return (
    <div style={style}>
      {label && (
        <Text variant="meta" customStyle={{ marginBottom: "12px" }}>
          {label}
        </Text>
      )}
      <Select
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
        styles={selectStyes({ hasError: false })}
        options={options}
        placeholder={placeholder}
        aria-label={label}
        aria-required="true"
        isDisabled={disabled}
        onChange={onChangeHandler}
        value={value}
      />
    </div>
  );
};

export default SelectInput;
