import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import {
  clearChatMessages as clearChatMessagesAction,
  showNotificationOverlay as showNotificationOverlayAction,
  fetchChatInfo as fetchChatInfoAction
} from "../../actions";

import useCountdown from "../../hooks/useCountdown";

import { NotificationTypes } from "../../constants";

type PropsType = {
  children: React.ReactChild;
  messages?: Message[];
  clearChatMessages?: () => void;
  fetchChatInfo?: (token: string, conversationId: number) => void;
  showNotificationOverlay?: typeof showNotificationOverlayAction;
};

type RouteParams = {
  token?: string;
};

const DEFAULT_TIMEOUT_MILLISECONDS: number = 1000 * 60 * 60; // 1 hour

type SessionManagerContextType = {
  sessionToken: string;
  endChatSession: () => void;
};

export const initialSessionManagerContext: SessionManagerContextType = {
  sessionToken: "",
  endChatSession: () => console.error("Session manager not initialized")
};

export const DeprecatedChatSessionManagerContext = React.createContext(
  initialSessionManagerContext
);

const DeprecatedChatSessionManager = ({
  children,
  messages,
  clearChatMessages,
  fetchChatInfo,
  showNotificationOverlay
}: PropsType): JSX.Element => {
  const params = useParams<RouteParams>();
  const [sessionToken, setSessionToken] = useState("");

  useEffect(() => {
    const { token = "" } = params;
    sessionStorage.setItem("token", token);
    setSessionToken(token);

    // Clear stored token on unmount
    return () => {
      sessionStorage.removeItem("token");
    };
  }, [params?.token]);

  const endChatSession = () => {
    const { token = "" } = params;
    if (token) {
      // fetching chat info generates new chat session
      const decodedToken: DecodedToken = jwtDecode(token);
      if (fetchChatInfo) fetchChatInfo(token, decodedToken.conversationId);
    }
    if (clearChatMessages) clearChatMessages();
    if (showNotificationOverlay) showNotificationOverlay(NotificationTypes.SESSION_EXPIRED);
  };

  const onInactiveTimeout = useCallback(() => {
    endChatSession();
  }, []);

  const { resetCountdown } = useCountdown({
    onTimeout: onInactiveTimeout,
    timeoutMilliseconds: DEFAULT_TIMEOUT_MILLISECONDS
  });

  useEffect(() => {
    resetCountdown();
  }, [messages?.length]);

  const sessionContext = useMemo(
    () => ({ sessionToken, endChatSession }),
    [endChatSession, sessionToken]
  );

  return (
    <DeprecatedChatSessionManagerContext.Provider value={sessionContext}>
      {children}
    </DeprecatedChatSessionManagerContext.Provider>
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {
    messages: state.chatMessages.messages
  };
};

export default connect(mapStateToProps, {
  clearChatMessages: clearChatMessagesAction,
  showNotificationOverlay: showNotificationOverlayAction,
  fetchChatInfo: fetchChatInfoAction
})(DeprecatedChatSessionManager);
