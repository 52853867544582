/* Map
======================================= */

import React from "react";
import { motion, useReducedMotion } from "framer-motion";
import theme from "../../../theme/default";

interface Props {
  className?: string;
  backgroundColor?: string;
  children: React.ReactNode;
}

const Drawer = ({ className, children, backgroundColor }: Props) => {
  const shouldReduceMotion = useReducedMotion();

  let panelAnimation: any = {
    initial: { opacity: 0, x: "100%" },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: "100%" },
    transition: { duration: 0.2 }
  };

  let insideAnimation: any = {
    initial: { opacity: 0, x: "100%" },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: "100%" },
    transition: { type: "spring", stiffness: 300, damping: 25, delay: 0.1 }
  };

  if (shouldReduceMotion) {
    panelAnimation = theme.animation.reduced;
    insideAnimation = theme.animation.reduced;
  }

  const style: React.CSSProperties = {
    backgroundColor: backgroundColor || "#fff"
  };

  const innerStyle: React.CSSProperties = {
    display: "flex",
    width: "100%"
  };

  return (
    <motion.section className={className} style={style} {...panelAnimation}>
      <motion.div style={innerStyle} {...insideAnimation}>
        {children}
      </motion.div>
    </motion.section>
  );
};

Drawer.displayName = "Drawer";

export default Drawer;
