/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* Input Field
======================================= */

import React, { useState, useEffect } from "react";
import cx from "classnames";

import TextareaAutosize from "react-textarea-autosize";

import { Icon, Text } from "../../../ui";

import styles from "./index.module.scss";

/* Props
--------------------------------------- */

interface Props {
  id?: string;
  name?: string;
  type?: string;
  placeholder?: string;
  icon?: React.ReactNode;
  minRows?: number;
  maxRows?: number;
  value?: string;
  label?: string;
  pattern?: string;
  inputMode?: "numeric";
  maxLength?: number;
  inputRef?: any; // React.RefObject<HTMLInputElement | HTMLTextAreaElement>
  autoFocus?: boolean;
  isOnCard?: boolean;
  customStyle?: React.CSSProperties;
  updateResponse?: (userResponse: string) => void;
  validate?: (value: string) => string | undefined;
}

/* Component
--------------------------------------- */

const Input: React.FC<Props> = ({
  id,
  value,
  pattern,
  inputMode,
  isOnCard = false,
  validate,
  placeholder = "",
  name = "",
  type = "text",
  minRows = 1,
  maxRows = 5,
  customStyle = {},
  ...otherProps
}: Props) => {
  const props = {
    placeholder,
    name,
    type,
    minRows,
    maxRows,
    ...otherProps
  };
  const [isFocused, setFocus] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const ref = props.inputRef ? props.inputRef : React.createRef();

  useEffect(() => {
    if (props.autoFocus) {
      if (ref && ref.current) {
        ref.current.focus();
      }
    }
  }, [props.autoFocus, ref]);

  const triggerFocus = (event: React.SyntheticEvent) => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  };

  const onFocus = () => setFocus(true);
  const onBlur = () => setFocus(false);

  const htmlIcon = props.icon || null;

  const htmlLabel = props.label ? (
    <Text variant="meta" as="label" customStyle={{ marginBottom: "6px", paddingLeft: "6px" }}>
      {props.label}
    </Text>
  ) : null;

  // decide which type of field to render

  let htmlField;

  if (props.maxRows && props.maxRows === 1)
    htmlField = (
      <input
        id={id}
        ref={ref}
        name={props.name}
        className={isOnCard ? styles.InputOnCard : ""}
        placeholder={props.placeholder}
        value={value}
        type={props.type}
        pattern={pattern}
        inputMode={inputMode}
        maxLength={props.maxLength}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={({ target }) => {
          if (validate) {
            const error = validate(target.value);
            setErrorMessage(error || null);
          }
          return props.updateResponse ? props.updateResponse(target.value) : () => null;
        }}
      />
    );
  else if (props.maxRows && props.maxRows > 1)
    htmlField = (
      <TextareaAutosize
        id={id}
        ref={ref}
        name={props.name}
        placeholder={props.placeholder}
        minRows={props.minRows}
        maxRows={props.maxRows}
        value={value}
        maxLength={props.maxLength}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={({ target }) => {
          if (validate) {
            const error = validate(target.value);
            setErrorMessage(error || null);
          }
          return props.updateResponse ? props.updateResponse(target.value) : () => null;
        }}
      />
    );

  return (
    <div
      className={cx(styles.Container, {
        [styles.isFocused]: isFocused,
        [styles.hasIcon]: props.icon
      })}
      style={customStyle}
      onClick={triggerFocus}
    >
      {htmlLabel}
      <div className={cx(styles.Field, { [styles.FieldOnCard]: isOnCard })}>
        {htmlIcon}
        {htmlField}
      </div>
      {errorMessage && <div className={styles.ErrorMessage}>{errorMessage}</div>}
    </div>
  );
};

Input.displayName = "Input";

export default Input;
