/* Image
======================================= */

import React from "react";
import cx from "classnames";

import styles from "./index.module.scss";

/* Props
--------------------------------------- */

interface Props {
  src: string;
}

/* Component
--------------------------------------- */

const Image: React.FC<Props> = ({ src }) => {
  return (
    <div className={cx(styles.Container, "ImageUI")}>
      <img src={src} alt="" />
    </div>
  );
};

Image.displayName = "Image";

export default Image;
