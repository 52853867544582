/* Link
======================================= */

import React from "react";

import Text, { VariantTypes } from "../Text/Text";

import { ChevronLeft, ChevronRight } from "../Icons";
import theme from "../../../theme/default";

interface Props {
  id?: string;
  showIcon?: boolean;
  textColor?: string;
  variant?: string;
  customStyle?: React.CSSProperties;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Link: React.FC<Props> = ({
  textColor = theme.colors.primary.blue,
  customStyle = {},
  variant = "medium",
  showIcon = true,
  ...otherProps
}) => {
  const props = {
    textColor,
    variant,
    ...otherProps
  };
  let icon: React.ReactNode = <ChevronRight />;
  let iconPosition = "right";

  let textProps = {
    variant: "medium" as VariantTypes,
    textColor: props.textColor,
    bold: true
  };

  if (props.variant === "back") {
    icon = <ChevronLeft />;
    iconPosition = "left";

    textProps = {
      variant: "small" as VariantTypes,
      textColor: theme.colors.grey[4],
      bold: true
    };
  }

  if (props.variant === "defaultUnbold") {
    textProps = {
      variant: "medium" as VariantTypes,
      textColor: props.textColor,
      bold: false
    };
  }

  const iconPositionStyle =
    iconPosition === "left" ? { marginRight: "10px" } : { marginLeft: "10px" };

  const style: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    ...customStyle
  };

  return (
    <button type="button" onClick={otherProps.onClick} style={style}>
      {showIcon && iconPosition === "left" && (
        <div style={{ ...iconPositionStyle, marginTop: "1px", color: theme.colors.grey[4] }}>
          {icon}
        </div>
      )}
      <Text {...textProps} textAlign="left">
        {props.children}
      </Text>
      {showIcon && iconPosition === "right" && (
        <div style={{ ...iconPositionStyle, marginTop: "1px", color: theme.colors.grey[4] }}>
          {icon}
        </div>
      )}
    </button>
  );
};

Link.displayName = "Link";

export default Link;
