/* SelectionContainer
======================================= */

import React from "react";

import { Text } from "../../../ui";
import styles from "./index.module.scss";

/* Props
--------------------------------------- */

interface Props {
  time?: string;
  children?: React.ReactNode;
  editClick: (event: React.MouseEvent) => void;
  canUndo?: boolean;
  customStyle?: React.CSSProperties;
  isDisabled?: boolean;
}

/* Component
--------------------------------------- */

const SelectionContainer: React.FC<Props> = ({
  editClick,
  canUndo,
  customStyle = {},
  isDisabled,
  ...props
}) => {
  return (
    <div className={styles.SelectionContainer} style={customStyle}>
      <div className={styles.Selection}>
        <div className={styles.Box}>{props.children}</div>
      </div>
      <Text variant="medium" className={styles.Meta}>
        {canUndo && (
          <button
            type="button"
            className={styles.EditButton}
            disabled={isDisabled}
            onClick={(e) => editClick(e)}
          >
            Edit
          </button>
        )}
      </Text>
    </div>
  );
};

SelectionContainer.displayName = "SelectionContainer";

export default SelectionContainer;
