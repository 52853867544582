import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const Phone = ({ size = 24, color }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.888 16.92c.013-.532-.357-.969-.85-1.039a13.849 13.849 0 01-3.032-.755.998.998 0 00-1.05.221l-1.27 1.27a1 1 0 01-1.202.162 17 17 0 01-6.375-6.375 1 1 0 01.162-1.201l1.266-1.266a1 1 0 00.224-1.057 13.817 13.817 0 01-.753-3.02A1.003 1.003 0 006.998 3H4a1 1 0 00-.996 1.074 18.8 18.8 0 002.92 8.24 18.512 18.512 0 005.7 5.697 18.775 18.775 0 008.175 2.913 1 1 0 001.09-1.004v-3zm-7.617-1.717l1.201-.162c-.22-.125-.436-.255-.648-.39l-.553.552zm9.617 4.713a3 3 0 01-3.288 2.998 20.779 20.779 0 01-9.058-3.22 20.49 20.49 0 01-6.303-6.3A20.804 20.804 0 011.012 4.27 3 3 0 013.998 1h2.99A3.002 3.002 0 019.99 3.59c.116.885.333 1.754.644 2.588a3.002 3.002 0 01-.679 3.17l-.717.716a15.002 15.002 0 004.586 4.586l.721-.721a2.999 2.999 0 013.163-.676c.836.312 1.706.529 2.6.647a3 3 0 012.58 3.031v2.985z"
        fill={color || "#494B50"}
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="1" y="1" width="22" height="22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.888 16.92c.013-.532-.357-.969-.85-1.039a13.849 13.849 0 01-3.032-.755.998.998 0 00-1.05.221l-1.27 1.27a1 1 0 01-1.202.162 17 17 0 01-6.375-6.375 1 1 0 01.162-1.201l1.266-1.266a1 1 0 00.224-1.057 13.817 13.817 0 01-.753-3.02A1.003 1.003 0 006.998 3H4a1 1 0 00-.996 1.074 18.8 18.8 0 002.92 8.24 18.512 18.512 0 005.7 5.697 18.775 18.775 0 008.175 2.913 1 1 0 001.09-1.004v-3zm-7.617-1.717l1.201-.162c-.22-.125-.436-.255-.648-.39l-.553.552zm9.617 4.713a3 3 0 01-3.288 2.998 20.779 20.779 0 01-9.058-3.22 20.49 20.49 0 01-6.303-6.3A20.804 20.804 0 011.012 4.27 3 3 0 013.998 1h2.99A3.002 3.002 0 019.99 3.59c.116.885.333 1.754.644 2.588a3.002 3.002 0 01-.679 3.17l-.717.716a15.002 15.002 0 004.586 4.586l.721-.721a2.999 2.999 0 013.163-.676c.836.312 1.706.529 2.6.647a3 3 0 012.58 3.031v2.985z"
          fill={color || "#fff"}
        />
      </mask>
    </svg>
  );
};

export default Phone;
