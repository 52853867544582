/* ScaleChoice
======================================= */

import React from "react";
import cx from "classnames";

import { Text } from "..";

import styles from "./index.module.scss";

/* Props
--------------------------------------- */

interface Props {
  option?: ScaleInputOption;
  onClick?: (event: React.MouseEvent) => void;
  isStatic?: boolean;
  isActive?: boolean;
}

/* Component
--------------------------------------- */
const ScaleChoice: React.FC<Props> = ({ option, isStatic, isActive, onClick }: Props) => {
  const { text, subText } = option || {};

  return (
    <button
      type="button"
      className={cx(styles.ScaleChoice, {
        [styles.isStatic]: isStatic,
        [styles.isActive]: isActive
      })}
      onClick={onClick}
    >
      <Text variant="titleXLarge" className={styles.Text}>
        {text}
      </Text>
      <Text variant="xsmall" className={styles.SubText}>
        {subText}
      </Text>
    </button>
  );
};

ScaleChoice.displayName = "ScaleChoice";

export default ScaleChoice;
