/* Button
======================================= */

import React, { useState } from "react";
import cx from "classnames";
import { Text, Loader } from "../../ui";
import { Check } from "../Icons";

import styles from "./index.module.scss";

/* Props
--------------------------------------- */

interface Props {
  id?: string;
  type?: "submit" | "button";
  customStyle?: React.CSSProperties;
  variant?: "primary" | "secondary" | "success" | "text" | "inline" | "textDanger" | "small";
  loading?: boolean;
  disabled?: boolean;
  disableOnClick?: boolean;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
}

/* Component
--------------------------------------- */

const Button: React.FC<Props> = ({
  loading,
  variant = "primary",
  customStyle = {},
  disabled = false,
  disableOnClick = false,
  ...otherProps
}) => {
  const props = {
    variant,
    loading,
    customStyle,
    disabled,
    disableOnClick,
    ...otherProps
  };

  const [isDisabled, setIsDisabled] = useState(false);

  const isButtonDisabled = isDisabled || loading || props.disabled;

  const onClick = (e: React.MouseEvent) => {
    if (props.disableOnClick) setIsDisabled(true);

    if (props.onClick) props.onClick(e);
  };

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={props.type}
      className={cx(styles.Button, {
        [styles.ButtonPrimary]: variant === "primary",
        [styles.ButtonSecondary]: variant === "secondary",
        [styles.ButtonSuccess]: variant === "success",
        [styles.ButtonText]: variant === "text",
        [styles.ButtonTextDanger]: variant === "textDanger",
        [styles.ButtonSmall]: variant === "small",
        [styles.ButtonInline]: variant === "inline",
        [styles.Disabled]: isButtonDisabled
      })}
      id={props.id}
      style={customStyle}
      onClick={(e) => onClick(e)}
    >
      {variant === "success" && (
        <div className={styles.ButtonIcon}>
          <Check size={18} />
        </div>
      )}
      {loading && (
        <div className={styles.ButtonIcon}>
          <Loader size={24} />
        </div>
      )}
      <Text as="span">{props.children}</Text>
    </button>
  );
};

Button.displayName = "Button";

export default Button;
