/* BookingCard
======================================= */

import React, { useEffect, useState, useMemo, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";

import TextCard from "../TextCard";
import { SelectFieldOption } from "../../Fields/SelectInput";

import { Button } from "../..";
import FiltersModal from "./FiltersModal";
import SearchModal from "./SearchModal";

import { ChatFlowResponseType, BookingMode } from "../../../../types";
import { Logo } from "../../Icons";

interface PropsType {
  selectedAppointmentSlots?: Array<AvailableSlot>;
  isCurrentMessage: boolean;
  msg: Message;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
}

const BookingCard = ({
  selectedAppointmentSlots = [],
  msg,
  isCurrentMessage,
  fetchNextMessage
}: PropsType) => {
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<SelectFieldOption | null>(null);
  const [selectedPractitioner, setSelectedPractitioner] = useState<SelectFieldOption | null>(null);
  const payloadOptions: BookingCardPayloadOptions = msg.payloadOptions as BookingCardPayloadOptions;
  const { bookingReason, locationOptions, practitionerOptions, organizationTimezone, patient } =
    payloadOptions;
  const patientPrimaryPractitioners = patient?.practitioners || [];

  const toggleFiltersModal = () => {
    setFiltersModalOpen(!filtersModalOpen);
  };
  const toggleSearchModal = () => {
    setSearchModalOpen(!searchModalOpen);
  };

  const filteredPractitionerOptions = useMemo(() => {
    const patientPrimaryProviderIds = patientPrimaryPractitioners.map((primaryPractitioner) =>
      primaryPractitioner?.id?.toString()
    );

    return practitionerOptions.filter((practitionerOption) => {
      // include default 'none' option
      if (practitionerOption.value === "") return true;

      if (!bookingReason?.requiresPrimaryPractitioner) return true;

      if (!patientPrimaryPractitioners) return false;

      if (patientPrimaryPractitioners.length === 0) return false;

      return patientPrimaryProviderIds.includes(practitionerOption.value.toString());
    });
  }, [
    practitionerOptions,
    patientPrimaryPractitioners,
    bookingReason?.requiresPrimaryPractitioner
  ]);

  const bookingMode: BookingMode = useMemo(() => {
    const { skipBooking, autoBookable } = bookingReason || {};

    if (skipBooking) {
      return BookingMode.REQUEST;
    }

    // Practitioner options with valid has id values (ie excluding 'none' option)
    const matchingPrimaryPractitioners = filteredPractitionerOptions.filter((practitionerOption) =>
      Boolean(practitionerOption.value)
    );

    let resolvedBookingMode = BookingMode.REQUEST;

    if (autoBookable && matchingPrimaryPractitioners.length > 0) {
      resolvedBookingMode = BookingMode.AUTO_BOOK;
    } else if (matchingPrimaryPractitioners.length > 0) {
      resolvedBookingMode = BookingMode.AVAILABLE_TIMES;
    }

    return resolvedBookingMode;
  }, [bookingReason]);

  const submit = (submitAsBookingMode?: BookingMode) => {
    const submittedMode = submitAsBookingMode || bookingMode;
    const showUserResponse = submittedMode !== BookingMode.REQUEST;
    const userResponse: BookingUserResponse = {
      responseType: ChatFlowResponseType.BOOKING,
      bookingMode: submittedMode,
      selectedAppointmentSlots: selectedAppointmentSlots || [],
      bookingReason
    };

    fetchNextMessage({
      lastReceivedMessageId: null,
      userResponse,
      chatFlowId: null,
      showUserResponse,
      userResponseType: ChatFlowResponseType.BOOKING,
      createdAt: moment().toDate()
    });

    if (filtersModalOpen) toggleFiltersModal();
    if (searchModalOpen) toggleSearchModal();
  };

  useEffect(() => {
    if (
      bookingMode === BookingMode.REQUEST ||
      (!patient && bookingMode === BookingMode.AUTO_BOOK)
    ) {
      submit(BookingMode.REQUEST);
    }
  }, [bookingMode, patient]);

  const cardText = bookingReason?.cardText || "";

  if (bookingMode === BookingMode.REQUEST || (!patient && bookingMode === BookingMode.AUTO_BOOK)) {
    return (
      <TextCard
        msg={{ ...msg, payloadContent: cardText }}
        index={1}
        avatar={<Logo size={18} />}
        customStyle={{ marginBottom: "16px" }}
      />
    );
  }

  return (
    <Fragment>
      <TextCard
        msg={{ ...msg, payloadContent: cardText }}
        index={1}
        avatar={<Logo size={18} />}
        customStyle={{ marginBottom: "16px" }}
      />
      {isCurrentMessage && (
        <Button
          onClick={() => {
            if (!(bookingReason?.hideLocationOptions && bookingReason?.hidePractitionerOptions)) {
              toggleFiltersModal();
            } else {
              // Open Search Modal if there are no filters to choose
              toggleSearchModal();
            }
          }}
          customStyle={{ marginBottom: "16px" }}
        >
          Continue booking process
        </Button>
      )}

      <FiltersModal
        open={filtersModalOpen}
        toggleModal={toggleFiltersModal}
        toggleSearchModal={toggleSearchModal}
        bookingReasonLabel={bookingReason?.label || ""}
        locationOptions={locationOptions}
        hideLocationOptions={!!bookingReason && bookingReason.hideLocationOptions}
        setSelectedLocation={setSelectedLocation}
        selectedLocation={selectedLocation}
        practitionerOptions={filteredPractitionerOptions}
        hidePractitionerOptions={!!bookingReason && bookingReason?.hidePractitionerOptions}
        setSelectedPractitioner={setSelectedPractitioner}
        selectedPractitioner={selectedPractitioner}
      />
      <SearchModal
        open={searchModalOpen}
        toggleModal={toggleSearchModal}
        bookingMode={bookingMode}
        bookingReason={bookingReason}
        submitSelectedSlots={submit}
        selectedLocation={selectedLocation}
        selectedPractitioner={selectedPractitioner}
        practitionerOptions={filteredPractitionerOptions}
        locationOptions={locationOptions}
        organizationTimezone={organizationTimezone}
        patient={patient}
      />
    </Fragment>
  );
};

function mapStateToProps({ booking }: ReduxState) {
  return {
    selectedAppointmentSlots: booking.selectedAppointmentSlots
  };
}

export default connect(mapStateToProps, null)(BookingCard);
