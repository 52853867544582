/* Instructions Card
======================================= */

import React from "react";
import { connect } from "react-redux";

import { Text, Avatar } from "..";
import JsonContentRenderEngine from "../../JsonContentRenderEngine";

import styles from "./index.module.scss";

/* Props
--------------------------------------- */

interface Props {
  index: number;
  msg?: Message;
  chatInfo?: ChatInfo;
  avatar?: any;
}

/* Component
--------------------------------------- */

const InstructionsCard: React.FC<Props> = (props) => {
  const { msg, avatar, chatInfo } = props;

  const { index } = props;
  // TODO: Make custom card PayloadOptions type
  const payloadOptions = msg?.payloadOptions as BasePayloadOptions;
  const jsonContent = payloadOptions.contentJson;
  const phoneNumber = chatInfo?.locations?.[0]?.phoneNumber || null;

  return (
    <div className={styles.InstructionCard} {...props}>
      {avatar && <Avatar>{avatar}</Avatar>}
      <div className={styles.CardContent}>
        {msg?.payloadContent && (
          <Text
            id={`text-${index}`}
            variant="large"
            customStyle={{ marginTop: "5px", marginBottom: "32px" }}
          >
            <>{msg.payloadContent}</>
          </Text>
        )}
        {jsonContent && (
          <JsonContentRenderEngine
            content={jsonContent}
            placeholderContext={{ locationNumber: phoneNumber || "" }}
            contentBoxShadow
          />
        )}
      </div>
    </div>
  );
};

InstructionsCard.displayName = "InstructionsCard";

function mapStateToProps(state: ReduxState) {
  return {
    chatInfo: state.chatInfo
  };
}

export default connect(mapStateToProps, null)(InstructionsCard);
