/* Box
======================================= */

import React from "react";
import { motion, useReducedMotion } from "framer-motion";
import theme from "../../../theme/default";

interface Props {
  id?: string;
  animate?: object;
  customStyle?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

const Box: React.FC<Props> = ({ id, animate, customStyle, className, children }) => {
  const shouldReduceMotion = useReducedMotion();

  if (animate) {
    const animation = !shouldReduceMotion ? animate : theme.animation.reduced;

    return (
      <motion.div id={id} {...animation}>
        <div className={className} id={id} style={customStyle}>
          {children}
        </div>
      </motion.div>
    );
  }
  return (
    <div className={className} id={id} style={customStyle}>
      {children}
    </div>
  );
};

Box.displayName = "Box";

export default Box;
