import React from "react";

import { Box, Scroll } from "../../../layout";
import { Button, Drawer, Text, Link } from "../..";
import SelectInput from "../../Fields/SelectInput";

import styles from "./index.module.scss";

interface PropsType {
  open: boolean;
  hideLocationOptions: boolean;
  hidePractitionerOptions: boolean;
  bookingReasonLabel: string;
  locationOptions: Array<any>;
  practitionerOptions: Array<any>;
  selectedPractitioner: Option | null;
  selectedLocation: Option | null;
  toggleModal: () => void;
  toggleSearchModal: () => void;
  setSelectedLocation: (selection: Option | null) => void;
  setSelectedPractitioner: (selection: Option | null) => void;
}

const FiltersModal = ({
  open,
  hidePractitionerOptions,
  practitionerOptions,
  setSelectedPractitioner,
  selectedPractitioner,
  hideLocationOptions,
  locationOptions,
  setSelectedLocation,
  selectedLocation,
  bookingReasonLabel,
  toggleModal,
  toggleSearchModal
}: PropsType) => {
  const checkAvailabilityReady =
    (hideLocationOptions || (!hideLocationOptions && selectedLocation !== null)) &&
    (hidePractitionerOptions || (!hidePractitionerOptions && selectedPractitioner !== null));

  if (!open) return null;

  return (
    <Drawer className={styles.Filters}>
      <Scroll>
        <Box className={styles.FiltersContentWrapper}>
          <header className={styles.FiltersModalHeader}>
            <Link variant="back" onClick={toggleModal}>
              Back to chat
            </Link>
          </header>
          <Text variant="titleMedium" customStyle={{ marginBottom: "24px" }}>
            Book your appointment
          </Text>
          <Text variant="meta" customStyle={{ marginBottom: "12px" }}>
            Reason
          </Text>
          <Text customStyle={{ marginBottom: "16px" }}>{bookingReasonLabel}</Text>
          {!hideLocationOptions && (
            <SelectInput
              label="Location"
              options={locationOptions}
              value={selectedLocation}
              onChange={(selection) => {
                setSelectedLocation(selection);
              }}
            />
          )}
          {!hidePractitionerOptions && (
            <SelectInput
              label="Provider"
              options={practitionerOptions}
              value={selectedPractitioner}
              onChange={setSelectedPractitioner}
            />
          )}

          <div className={styles.FiltersFooter}>
            <Button onClick={toggleSearchModal} disabled={!checkAvailabilityReady}>
              Check Availability
            </Button>
          </div>
        </Box>
      </Scroll>
    </Drawer>
  );
};

export default FiltersModal;
