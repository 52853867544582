import * as React from "react";
import Text from "../../components/ui/Text/Text";
import { Drawer, Card, Avatar, Button } from "../../components/ui";
import { Scroll } from "../../components/layout";
import { Logo } from "../../components/ui/Icons";

import theme from "../../theme/default";
import styles from "./index.module.scss";

const Home: React.FC = () => {
  return (
    <Drawer backgroundColor={theme.colors.grey[1]}>
      <Scroll>
        <div className={styles.Container}>
          <Card customStyle={{ padding: "24px 0px 24px 0px" }}>
            <div className={styles.InnerContainer}>
              <Avatar size={40}>
                <Logo size={18} />
              </Avatar>
              <Text
                variant="titleSmall"
                bold
                customStyle={{ paddingTop: "12px", paddingBottom: "12px" }}
              >
                Hi there!
              </Text>
              <Text variant="small" customStyle={{ paddingBottom: "12px" }}>
                We’re Mikata Health, a health tech startup based in Calgary, Alberta, Canada.
              </Text>
              <Text variant="small" customStyle={{ paddingBottom: "24px" }}>
                We help clinics deliver outstanding care with tools that make patient communication
                proactive, personal, and convenient.
              </Text>
              <div className={styles.Button}>
                <Button customStyle={{ width: "67%" }} variant="small">
                  <a href="https://www.mikatahealth.com" target="_blank" rel="noopener noreferrer">
                    Learn More
                  </a>
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </Scroll>
    </Drawer>
  );
};

export default Home;
