/* Text
======================================= */

import React from "react";
import cx from "classnames";

import styles from "./index.module.scss";
import theme from "../../../theme/default";

export const textVariants = {
  xsmall: {
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.25px",
    color: theme.colors.grey[4]
  },
  small: {
    fontSize: "14px",
    lineHeight: "16px",
    color: theme.colors.grey[4]
  },
  medium: {
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.colors.grey[4]
  },
  large: {
    fontSize: "18px",
    lineHeight: "24px",
    color: theme.colors.grey[4]
  },
  titleSmall: {
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: theme.colors.grey[4]
  },
  titleMedium: {
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "0px",
    color: theme.colors.grey[4]
  },
  titleLarge: {
    fontSize: "28px",
    lineHeight: "32px",
    letterSpacing: "-0.75px",
    color: theme.colors.grey[4]
  },
  titleXLarge: {
    fontSize: "32px",
    lineHeight: "40px",
    letterSpacing: "-1px",
    color: theme.colors.grey[4]
  },
  meta: {
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.25px",
    textTransform: "uppercase",
    color: theme.colors.grey[4]
  }
};

export type VariantTypes = keyof typeof textVariants;

type Props = {
  id?: string;
  variant?: VariantTypes;
  as?: string;
  fontFamily?: string;
  textColor?: string;
  className?: string;
  bold?: boolean;
  uppercase?: boolean;
  children?: React.ReactNode;
  error?: boolean;
  overflowWrap?: string;
  textAlign?: string;
  customStyle?: React.CSSProperties;
};

const Text: React.FC<Props> = ({
  variant = "medium",
  fontFamily = "body",
  customStyle = {},
  textColor = "",
  as,
  className,
  ...otherProps
}) => {
  console.log(className);
  const props = { variant, fontFamily, ...otherProps };
  const boldFromProp = props.bold
    ? {
        fontFamily: theme.fonts.bold
      }
    : {};
  const uppercaseFromProp = props.uppercase
    ? {
        textTransform: "uppercase"
      }
    : {};
  const errorFromProp = props.error
    ? {
        color: theme.colors.error.text
      }
    : {};
  const wrapTextFromProp = props.overflowWrap
    ? {
        overflowWrap: props.overflowWrap
      }
    : {};
  const textAlignFromProp = props.textAlign
    ? {
        textAlign: props.textAlign
      }
    : {};
  const textColorFromProp = textColor
    ? {
        color: textColor
      }
    : {};

  const style = {
    ...textVariants[variant],
    ...boldFromProp,
    ...uppercaseFromProp,
    ...errorFromProp,
    ...wrapTextFromProp,
    ...textAlignFromProp,
    ...textColorFromProp,
    ...customStyle
  } as React.CSSProperties;

  if (as === "label") {
    return (
      <label className={cx(styles.Container, "text-ui", className)} style={style}>
        {props.children}
      </label>
    );
  }

  if (as === "span") {
    return (
      <span className={cx(styles.Container, "text-ui", className)} style={style}>
        {props.children}
      </span>
    );
  }

  return (
    <div className={cx(styles.Container, "text-ui", className)} style={style}>
      {props.children}
    </div>
  );
};

Text.displayName = "Text";

export default Text;
