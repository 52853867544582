/* SelectionEntry
======================================= */

import React from "react";

import { Text } from "../../../ui";

/* Props
--------------------------------------- */

interface Props {
  icon?: React.ReactNode;
  children?: React.ReactNode;
  iconColor?: string;
  customStyle?: React.CSSProperties;
  bold?: boolean;
  overflowWrap?: string;
}

/* Component
--------------------------------------- */

const SelectionEntry: React.FC<Props> = (props) => {
  const customStyle = props.customStyle || {};
  return (
    <div style={{ display: "flex", ...customStyle }} className="selection-entry">
      {props.icon && <div style={{ marginRight: "9px" }}>{props.icon}</div>}

      <Text overflowWrap={props.overflowWrap} bold={props.bold}>
        {props.children}
      </Text>
    </div>
  );
};

SelectionEntry.displayName = "SelectionEntry";

export default SelectionEntry;
