import React from "react";
import { connect } from "react-redux";

import { fetchApptInstructions as fetchApptInstructionsAction } from "../../../actions";

import { ChatLoader } from "..";

import JsonContentRenderEngine from "../../JsonContentRenderEngine";
interface InstructionChecklistProps {
  fetchApptInstructions(token: string, params: ChecklistInfoActionData): void;
  breadcrumbs: Array<Breadcrumb>;
  checklistInfo: ChecklistInfoStore;
  token: string;
  fetchingMessage: boolean;
}

interface InstructionChecklistState {
  checklistId: number;
}

class InstructionChecklist extends React.Component<
  InstructionChecklistProps,
  InstructionChecklistState
> {
  constructor(props: InstructionChecklistProps) {
    super(props);
    const { breadcrumbs } = this.props;

    const latestBreadCrumbInput = breadcrumbs
      .reverse()
      .reduce<string | null>((acc: string | null, breadcrumb: Breadcrumb) => {
        if (!acc && breadcrumb.input) {
          acc = breadcrumb.input;
        }
        return acc;
      }, null);

    this.state = {
      checklistId: latestBreadCrumbInput ? parseInt(latestBreadCrumbInput, 10) : 0
    };
  }

  async componentDidMount() {
    const { token, fetchApptInstructions } = this.props;
    const { checklistId } = this.state;

    fetchApptInstructions(token, {
      checklistId
    });
  }

  render() {
    const { checklistInfo, fetchingMessage } = this.props;
    const { checklistId } = this.state;
    const checklist = checklistId ? checklistInfo.checklistsById[checklistId] : null;

    if (!checklist && !fetchingMessage) return <ChatLoader />;

    if (!checklist) return null;

    return <JsonContentRenderEngine content={checklist.content} />;
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    breadcrumbs: state.chatMessages.breadcrumb,
    checklistInfo: state.checklistInfo
  };
}

export default connect(mapStateToProps, {
  fetchApptInstructions: fetchApptInstructionsAction
})(InstructionChecklist);
