import React from "react";

type PropsType = {
  size?: number;
};

const Logo = ({ size = 20 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size * (347 / 461)}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 461 347"
      fill="reset"
    >
      <path
        d="M269.43 58.5A108.129 108.129 0 0 0 196 87.22a9.19 9.19 0 0 1-12.5 0 108.129 108.129 0 0 0-73.43-28.72C49.69 58.5.75 108.05.75 169.17v146.61c0 16.27 12.49 30.12 28.55 30.7a29.803 29.803 0 0 0 21.738-8.469 29.805 29.805 0 0 0 8.982-21.531v-146c0-28 22.41-50.67 50-50.67 27.64 0 50.05 22.69 50.05 50.67v145.3c0 16.27 12.49 30.12 28.55 30.7a29.805 29.805 0 0 0 28.417-18.32 29.797 29.797 0 0 0 2.293-11.68v-146c0-28 22.41-50.67 50.05-50.67 27.64 0 50.05 22.69 50.05 50.67v145.3c0 16.27 12.5 30.12 28.56 30.7a29.805 29.805 0 0 0 28.417-18.32 29.797 29.797 0 0 0 2.293-11.68V169.17c.05-61.12-48.89-110.67-109.27-110.67Z"
        fill="#003125"
      />
      <path
        d="M30.75 59c16.292 0 29.5-13.208 29.5-29.5S47.042 0 30.75 0 1.25 13.208 1.25 29.5 14.458 59 30.75 59Z"
        fill="#00D598"
      />
      <path
        d="M430.75 347c16.292 0 29.5-13.208 29.5-29.5s-13.208-29.5-29.5-29.5-29.5 13.208-29.5 29.5 13.208 29.5 29.5 29.5Z"
        fill="#FF878F"
      />
    </svg>
  );
};

export default Logo;
