import React, { useImperativeHandle } from "react";
import { useMask } from "@react-input/mask";

import { Input } from "../../../ui";
import { Phone } from "../../Icons";

interface Props {
  id?: string;
  name?: string;
  customStyle: React.CSSProperties;
  inputRef?: any; // React.RefObject<HTMLInputElement | HTMLTextAreaElement>
  updateResponse?: (userResponse: string) => void;
}

const PhoneInput: React.FC<Props> = (props) => {
  const { name = "", inputRef = null, id, updateResponse, customStyle = {} } = props;
  const maskRef = useMask({ mask: "(xxx) xxx-xxxx", replacement: { x: /\d/ }, showMask: true });
  useImperativeHandle(inputRef, () => maskRef.current);
  return (
    <div style={customStyle} id={id}>
      <Input
        inputRef={maskRef}
        name={name}
        updateResponse={updateResponse}
        maxRows={1}
        icon={<Phone />}
        type="tel"
        label="Enter Phone Number"
      />
    </div>
  );
};

PhoneInput.displayName = "PhoneInput";

export default PhoneInput;
