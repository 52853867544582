import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const Letter = ({ size = 24, color }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3h16a3.006 3.006 0 013 3.018V18c0 1.652-1.348 3-3 3H4c-1.652 0-3-1.348-3-3V6c0-1.652 1.348-3 3-3zm16.893 2.554A1.006 1.006 0 0020 5H4c-.388 0-.728.227-.893.554L12 11.779l8.893-6.225zM3 7.921l8.427 5.898a1 1 0 001.146 0L21 7.921V18c0 .548-.452 1-1 1H4c-.548 0-1-.452-1-1V7.92z"
        fill={color || "#494B50"}
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="1" y="3" width="23" height="18">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 3h16a3.006 3.006 0 013 3.018V18c0 1.652-1.348 3-3 3H4c-1.652 0-3-1.348-3-3V6c0-1.652 1.348-3 3-3zm16.893 2.554A1.006 1.006 0 0020 5H4c-.388 0-.728.227-.893.554L12 11.779l8.893-6.225zM3 7.921l8.427 5.898a1 1 0 001.146 0L21 7.921V18c0 .548-.452 1-1 1H4c-.548 0-1-.452-1-1V7.92z"
          fill={color || "#fff"}
        />
      </mask>
    </svg>
  );
};

export default Letter;
