import React, { Component, ErrorInfo } from "react";

import { saveAppMetrics, AppMetricType } from "../hooks/useAppAnalytics";
import Text from "../components/ui/Text/Text";
import { Drawer, Card, Avatar } from "../components/ui";
import { Logo } from "./ui/Icons";
import { Scroll } from "../components/layout";
import ExceptionGraphic from "./ui/NotificationOverlay/ExceptionGraphic";

import styles from "./index.module.scss";
import theme from "../theme/default";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PropsType = any;
type StateType = { hasError: boolean; error?: Error };

class ErrorBoundary extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service like AppSignal
    // logErrorToMyService(error, errorInfo);
    saveAppMetrics([
      {
        type: AppMetricType.APPLICATION_ERROR,
        errorMessage: error.message || "Unknown Application Error",
        errorInfo: errorInfo ? JSON.stringify(errorInfo) : "",
        userAgent: window?.navigator?.userAgent || "",
        timestamp: new Date().toISOString()
      }
    ]);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Drawer backgroundColor={theme.colors.grey[1]}>
          <Scroll>
            <div className={styles.Container}>
              <Card customStyle={{ padding: "24px 0px 24px 0px" }}>
                <div className={styles.Card}>
                  <Avatar size={40}>
                    <Logo size={18} />
                  </Avatar>
                  <Text
                    variant="titleSmall"
                    bold
                    customStyle={{ paddingTop: "12px", paddingBottom: "12px" }}
                  >
                    Oh dear! Something went wrong.
                  </Text>
                  <Text variant="small" customStyle={{ paddingBottom: "12px" }}>
                    Our team is working to fix the issue. Apologies for the inconvenience{" "}
                  </Text>
                  <ExceptionGraphic />
                </div>
              </Card>
            </div>
          </Scroll>
        </Drawer>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
