import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const MultipleOff = ({ size = 24, color }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0223 0C17.8664 0 18.5776 0.285265 19.1559 0.855803C19.7343 1.42634 20.0234 2.14146 20.0234 3.00117V16.9988C20.0234 17.8429 19.7343 18.5541 19.1559 19.1325C18.5776 19.7108 17.8664 20 17.0223 20H3.00117C2.15709 20 1.44588 19.7108 0.867526 19.1325C0.289173 18.5541 0 17.8429 0 16.9988V3.00117C0 2.14146 0.289173 1.42634 0.867526 0.855803C1.44588 0.285265 2.15709 0 3.00117 0H17.0223ZM18.007 16.9988V3.00117C18.007 2.68855 17.9172 2.44236 17.7374 2.2626C17.5576 2.08284 17.3193 1.99297 17.0223 1.99297H3.00117C2.70418 1.99297 2.46581 2.08284 2.28605 2.2626C2.10629 2.44236 2.01641 2.68855 2.01641 3.00117V16.9988C2.01641 17.2958 2.10629 17.5381 2.28605 17.7257C2.46581 17.9132 2.70418 18.007 3.00117 18.007H17.0223C17.3193 18.007 17.5576 17.9132 17.7374 17.7257C17.9172 17.5381 18.007 17.2958 18.007 16.9988Z"
        fill={color || "#D7D8DB"}
      />
    </svg>
  );
};

export default MultipleOff;
