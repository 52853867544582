/* Modal Overlay
======================================= */

import React from "react";
import cx from "classnames";

import { Close } from "../Icons";
import { motion } from "framer-motion";

import styles from "./index.module.scss";
import theme from "../../../theme/default";

/* Props
--------------------------------------- */

interface Props {
  as?: any;
  children?: React.ReactNode;
  showing: boolean;
  setShowing?: (showing: boolean) => void;
  disableCloseButton?: boolean;
}

/* Component
--------------------------------------- */

const Modal: React.FC<Props> = (props) => {
  const { showing = false, setShowing, children, disableCloseButton } = props;

  const animateMainIn = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { type: "spring", stiffness: 300, damping: 18 }
  };

  const animateContentIn = {
    initial: { opacity: 0, y: 100 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 100 },
    transition: { type: "spring", stiffness: 300, damping: 18 }
  };

  return (
    <div className={cx(styles.Wrapper, { [styles.Showing]: showing })}>
      {showing && (
        <motion.div className={styles.Container} {...props} {...animateMainIn}>
          <motion.div {...animateContentIn} className={styles.OverlayContent}>
            {setShowing && (
              <div className={styles.OverlayHeader}>
                <button
                  type="button"
                  className="btn-close"
                  disabled={disableCloseButton}
                  onClick={() => setShowing && setShowing(false)}
                >
                  <div
                    style={{
                      color: disableCloseButton ? theme.colors.grey[2] : theme.colors.grey[4]
                    }}
                  >
                    <Close />
                  </div>
                </button>
              </div>
            )}

            <div className="overlay-body">{children}</div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

Modal.displayName = "Modal";

export default Modal;
