/* ReduxProvider
======================================= */

import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";

import { thunk } from "redux-thunk";

import reducers from "./reducers";

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

type PropsType = {
  children: React.ReactElement;
};

const ReduxProvider = ({ children }: PropsType) => {
  return (
    <Provider
      store={createStoreWithMiddleware(
        reducers,
        // eslint-disable-next-line no-underscore-dangle
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
          (window as any).__REDUX_DEVTOOLS_EXTENSION__()
      )}
    >
      {children}
    </Provider>
  );
};
export default ReduxProvider;
