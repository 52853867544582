import React from "react";

import { Button, Card, Modal, Text } from "../../../ui";
import PaperclipIcon from "../../Icons/PaperclipIcon";
import DownloadIcon from "../../Icons/DownloadIcon";
import DeleteIcon from "../../Icons/DeleteIcon";
import IconButton from "../../Icons/IconButton";

import styles from "./index.module.scss";

type Props = {
  item: SecureMessageItem;
  onDelete: (id: number) => void;
};

const SecureMessageFileItem: React.FC<Props> = ({ item, onDelete }) => {
  const [downloadModalOpen, setDownloadModalOpen] = React.useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);

  const handleDownloadRecordingFile = () => {
    window.open(item.signedS3Url, "_blank")?.focus();
    setDownloadModalOpen(false);
  };

  const handleDeleteRecordingFile = () => {
    onDelete(item.id);
    setDeleteModalOpen(false);
  };

  return (
    <Card className={styles.Wrapper}>
      {item.signedS3Url && (
        <div className={styles.Item}>
          <div className={styles.Item}>
            <PaperclipIcon />
          </div>
        </div>
      )}

      <div className={styles.Item}>
        <Text variant={!item.payloadContent ? "titleSmall" : undefined} bold={!item.payloadContent}>
          {item.payloadContent || item.clinicFileName}
        </Text>
      </div>
      <div className={styles.Item}>
        <div className={styles.IconWrapper}>
          {item.signedS3Url && (
            <IconButton
              icon={DownloadIcon}
              onClick={() => setDownloadModalOpen(true)}
              target="_blank"
            />
          )}
          <IconButton icon={DeleteIcon} onClick={() => setDeleteModalOpen(true)} />
        </div>
      </div>
      <Modal showing={deleteModalOpen} setShowing={setDeleteModalOpen}>
        <div className={styles.ModalContent}>
          <Text variant="titleSmall" bold>
            Delete your file?
          </Text>
          <Text>Are you sure you want to delete your file? You cannot undo this action.</Text>
          <div className={styles.ButtonWrapper}>
            <Button variant="small" onClick={() => handleDeleteRecordingFile()}>
              Yes, delete file
            </Button>
            <Button variant="text" onClick={() => setDeleteModalOpen(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      <Modal showing={downloadModalOpen} setShowing={setDownloadModalOpen}>
        <div className={styles.ModalContent}>
          <Text variant="titleSmall" bold>
            Caution! Your download may contain sensitive information
          </Text>
          <Text>Only provide access to your downloaded file(s) to people you trust. </Text>
          <Text>Posting this information on websites or social media is not recommended.</Text>
          <div className={styles.ButtonWrapper}>
            <Button variant="small" onClick={() => handleDownloadRecordingFile()}>
              I understand
            </Button>
            <Button variant="text" onClick={() => setDownloadModalOpen(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </Card>
  );
};

export default SecureMessageFileItem;
