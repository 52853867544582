/* Field Error
======================================= */

import React from "react";

import { Error } from "../../Icons";
import { Text } from "../..";

import theme from "../../../../theme/default";

type Props = {
  customStyle?: React.CSSProperties;
  children: React.ReactNode;
};

/* Component
--------------------------------------- */

const FieldError: React.FC<Props> = ({ customStyle = {}, children }) => {
  const style: React.CSSProperties = {
    display: "flex",
    padding: "0 10px",
    ...customStyle
  };
  return (
    <div style={style}>
      <span style={{ marginRight: "8px" }}>
        <Error size={18} color={theme.colors.error.text} />
      </span>

      <Text variant="small" textColor={theme.colors.error.text}>
        {children}
      </Text>
    </div>
  );
};

FieldError.displayName = "FieldError";

export default FieldError;
