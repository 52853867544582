import React, { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { LinkExpired, LinkResent } from "../Icons";

import {
  clearNotificationOverlay,
  sendRefreshLink as sendRefreshLinkAction,
  SendRefreshLinkActionData
} from "../../../actions";
import { Button, Modal, Text } from "..";
import useNotificationOverlay from "../../../hooks/useNotificationOverlay";
import { NotificationTypes } from "../../../constants";
import theme from "../../../theme/default";

import ExceptionGraphic from "./ExceptionGraphic";

import styles from "./index.module.scss";

type RouteParams = {
  shortCode?: string;
};

type ErrorContent = {
  icon: string | React.ReactNode;
  heading: string;
  body: string[];
  showPrivacyPolicyLink?: boolean;
  clearNotificationButton?: boolean;
  refreshLinkButton?: boolean;
};

interface PropsType {
  clearNotificationOverlay: () => void;
  sendRefreshLink: (params: SendRefreshLinkActionData) => void;
  sendRefreshLinkError?: boolean;
  sendRefreshLinkLoading?: boolean;
}

const NotificationOverlay = ({
  clearNotificationOverlay,
  sendRefreshLink,
  sendRefreshLinkError,
  sendRefreshLinkLoading
}: PropsType) => {
  const params = useParams<RouteParams>();
  const { shortCode = "" } = params;
  const [requestedRefreshLink, setRequestedRefreshLink] = useState(false);
  const { notification } = useNotificationOverlay();

  const content = React.useMemo<ErrorContent>(() => {
    switch (notification.type) {
      case NotificationTypes.ACCESS_BLOCKED:
        return {
          icon: <ExceptionGraphic />,
          heading: "Your access is blocked",
          body: ["You have reached the limit of attempts permitted to log in."],
          showPrivacyPolicyLink: false
        };
      case NotificationTypes.SECURE_MESSAGE_EXPIRED:
        return {
          icon: <ExceptionGraphic />,
          heading: "Your message has expired",
          body: [
            "Secure messages expire 24 hours after they are sent. You must contact your clinic and ask them to send another message."
          ],
          showPrivacyPolicyLink: true
        };
      case NotificationTypes.LINK_EXPIRED:
        return {
          icon: <ExceptionGraphic />,
          heading: "This link is no longer available",
          body: ["This link has expired and is no longer available."],
          showPrivacyPolicyLink: true
        };
      case NotificationTypes.LINK_EXPIRED_CAN_REFRESH:
        return {
          icon: <LinkExpired />,
          heading: "This link has expired",
          body: ["This link has expired. Tap the button below to receive a new one."],
          refreshLinkButton: true
        };
      case NotificationTypes.LINK_RESENT:
        return {
          icon: <LinkResent />,
          heading: "Link resent",
          body: [
            `Please check your SMS or email inbox for your new link. ${
              requestedRefreshLink
                ? "If you don't receive a link within a minute, please contact your clinic."
                : ""
            }`
          ]
        };
      case NotificationTypes.SESSION_EXPIRED:
        return {
          icon: <ExceptionGraphic />,
          heading: "Session timed out",
          body: [
            "For privacy and security reasons, we have logged you out.",
            "Please enter again to view your chat"
          ],
          showPrivacyPolicyLink: true,
          clearNotificationButton: true
        };
      case NotificationTypes.GENERIC_APPLICATION_ERROR:
      default:
        return {
          icon: <ExceptionGraphic />,
          heading: "Oops!",
          body: ["Please check your device's internet connection and try again."]
        };
    }
  }, [notification]);

  if (!notification.visible) return null;

  return (
    <Modal showing={notification.visible}>
      <div className={styles.Content}>
        <div className={styles.GraphicWrapper}>{content.icon}</div>
        <Text variant="titleMedium">{content.heading}</Text>
        {content.body.map((text) => (
          <Text key={text}>{text}</Text>
        ))}
        {content.showPrivacyPolicyLink && (
          <Text variant="small">
            <a
              style={{ color: theme.colors.primary.blue }}
              href="https://mikatahealth.com/privacy_policy"
              target="_blank"
              rel="noreferrer"
            >
              See our privacy policy
            </a>
          </Text>
        )}
        {content.clearNotificationButton && (
          <div style={{ marginTop: "2px" }}>
            <Button onClick={() => clearNotificationOverlay()}>Ok, I understand</Button>
          </div>
        )}
        {content.refreshLinkButton && (
          <div style={{ marginTop: "2px" }}>
            <Button
              variant="secondary"
              loading={sendRefreshLinkLoading}
              onClick={() => {
                sendRefreshLink({ shortCode });
                setRequestedRefreshLink(true);
              }}
            >
              Resend Link
            </Button>
            {sendRefreshLinkError && (
              <Text error>Oops! Something went wrong. Please try again.</Text>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ messages }: ReduxState) => {
  return {
    sendRefreshLinkError: messages?.sendRefreshLinkError,
    sendRefreshLinkLoading: messages?.sendRefreshLinkLoading
  };
};

export default connect(mapStateToProps, {
  clearNotificationOverlay,
  sendRefreshLink: sendRefreshLinkAction
})(NotificationOverlay);
