/* Image
======================================= */

import React from "react";
import cx from "classnames";

import spacerlarge from "../../../../assets/img/spacer_295x180.png";

import theme from "../../../../theme/default";
import styles from "./index.module.scss";

/* Props
--------------------------------------- */

interface Props {
  src: string;
  size: string;
  customStyle?: React.CSSProperties;
}

/* Component
--------------------------------------- */

const ImageContain: React.FC<Props> = ({ src, size = "contain", customStyle = {} }) => {
  const style: React.CSSProperties = {
    backgroundImage: `url(${src})`,
    backgroundSize: `${size}`,
    backgroundColor: theme.colors.grey[1],
    ...customStyle
  };
  return (
    // do not remove ImageUI (gets applied from SelectionContainer component on need basis)
    <div style={style} className={cx(styles.Container, "ImageUI")}>
      <img src={spacerlarge} alt="" />
    </div>
  );
};

ImageContain.displayName = "ImageContain";

export default ImageContain;
