/* SummaryCard
======================================= */
import React, { Fragment } from "react";

import Summary from "../Summary/Summary";
import TextCard from "./TextCard";
import TextInputCard from "../ChatCards/TextInputCard";
import DateInputCard from "../ChatCards/DateInputCard";
import PhoneInputCard from "../ChatCards/PhoneInputCard";
import NumberInputCard from "./NumberInputCard";
import { Button, Modal, Text } from "..";
import { Logo } from "../Icons";

import { ChatCardTypes } from "../../../constants";
import { updateUserResponse } from "../../../lib";

import moment from "moment";

interface Props {
  isCurrentMessage: boolean;
  msg: Message;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  chatInfo: ChatInfo;
  token: string;
}

interface State {
  modalShowing: boolean;
  showingCardType: string;
  chatFlowMessageId: number;
  apptRequestField: boolean;
  label: string;
  summaryInfo: Array<ChatSummaryItem>;
}

interface ChatAnswer {
  cancelOption?: boolean;
  sendOption?: boolean;
  text: string;
  value: string;
}

const isEditable = (chatCardType: string) => {
  return {
    [ChatCardTypes.TEXT_INPUT]: true,
    [ChatCardTypes.PHONE_INPUT]: true,
    [ChatCardTypes.NUMBER_INPUT]: true,
    [ChatCardTypes.DATE_INPUT]: true,
    [ChatCardTypes.MULTI_SELECT]: false
  }[chatCardType];
};

class SummaryCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // TODO: Make custom card PayloadOptions type
    const payloadOptions = this.props.msg?.payloadOptions as BasePayloadOptions;

    this.state = {
      modalShowing: false,
      showingCardType: "",
      chatFlowMessageId: -1,
      apptRequestField: false,
      label: "",
      summaryInfo: payloadOptions?.summaryInfo
    };
  }

  submit = (userResponse: string) => {
    this.props.fetchNextMessage({
      lastReceivedMessageId: null,
      userResponse,
      chatFlowId: null,
      showUserResponse: true,
      createdAt: moment().toDate()
    });
  };

  save = async (userResponse: string | string[]) => {
    const { chatFlowMessageId, apptRequestField, summaryInfo } = this.state;
    const { chatInfo, token } = this.props;
    const { sessionId } = chatInfo;

    await updateUserResponse(chatFlowMessageId, userResponse, sessionId, apptRequestField, token);

    const summaryItem = summaryInfo.find((item) => item.chatFlowMessageId === chatFlowMessageId);
    if (summaryItem) {
      summaryItem.value = String(userResponse);
    }

    this.setState({ modalShowing: false, summaryInfo });
  };

  setModalShowing = (
    chatCardType: string,
    chatFlowMessageId: number,
    label: string,
    apptRequestField: boolean
  ) => {
    const { modalShowing } = this.state;
    this.setState({
      modalShowing: !modalShowing,
      showingCardType: chatCardType,
      chatFlowMessageId,
      apptRequestField,
      label
    });
  };

  renderEditCard = () => {
    const { showingCardType, summaryInfo, chatFlowMessageId } = this.state;
    const summaryItem = summaryInfo.find((item) => item.chatFlowMessageId === chatFlowMessageId);
    // TODO: Make custom card PayloadOptions type
    const payloadOptions = this.props.msg?.payloadOptions as BasePayloadOptions;

    switch (showingCardType) {
      case ChatCardTypes.TEXT_INPUT:
        return (
          <TextInputCard
            msg={{
              payloadContent: "",
              cardType: ChatCardTypes.TEXT_INPUT,
              sender: "",
              chatFlowMessageId: 0
            }}
            isCurrentMessage
            fetchNextMessage={() => {}}
            index={0}
            editMode
            save={this.save}
          />
        );

      case ChatCardTypes.PHONE_INPUT:
        return (
          <PhoneInputCard
            msg={{
              payloadContent: "",
              cardType: ChatCardTypes.PHONE_INPUT,
              sender: "",
              chatFlowMessageId: 0
            }}
            isCurrentMessage
            fetchNextMessage={() => {}}
            index={0}
            editMode
            save={this.save}
          />
        );

      case ChatCardTypes.NUMBER_INPUT:
        return (
          <NumberInputCard
            msg={{
              payloadContent: "",
              cardType: ChatCardTypes.NUMBER_INPUT,
              sender: "",
              chatFlowMessageId: 0,
              payloadOptions: {
                maxDigits: (summaryItem?.payloadOptions as BasePayloadOptions)?.maxDigits || 9
              }
            }}
            isCurrentMessage
            fetchNextMessage={() => {}}
            index={0}
            editMode
            save={this.save}
          />
        );
      case ChatCardTypes.DATE_INPUT:
        return (
          <DateInputCard
            msg={{
              payloadContent: "",
              cardType: ChatCardTypes.DATE_INPUT,
              sender: "",
              chatFlowMessageId: 0,
              payloadOptions: {
                format: (summaryItem?.payloadOptions as BasePayloadOptions)?.format || "YYYY-MM-DD"
              }
            }}
            isCurrentMessage
            fetchNextMessage={() => {}}
            index={0}
            editMode
            save={this.save}
          />
        );

      default:
        return null;
    }
  };

  render() {
    const { msg, isCurrentMessage } = this.props;
    const { modalShowing, label, summaryInfo } = this.state;

    let sendOptionText = "";
    let sendOptionValue = "";
    let cancelOptionText = "";
    let cancelOptionValue = "";
    // TODO: Make custom card PayloadOptions type
    const payloadOptions = this.props.msg?.payloadOptions as BasePayloadOptions;

    if (payloadOptions?.answers) {
      const sendOption = payloadOptions.answers.find((option: ChatAnswer) => option.sendOption);
      sendOptionText = sendOption.text;
      sendOptionValue = sendOption.value;
      const cancelOption = payloadOptions.answers.find((option: ChatAnswer) => option.cancelOption);
      cancelOptionText = cancelOption.text;
      cancelOptionValue = cancelOption.value;
    }

    return (
      <Fragment>
        <TextCard
          msg={msg}
          index={1}
          avatar={<Logo size={18} />}
          customStyle={{ marginBottom: "16px" }}
        >
          <>{msg.payloadContent}</>
        </TextCard>{" "}
        {summaryInfo.map((item) => (
          <Summary
            label={item.label}
            editClick={() =>
              this.setModalShowing(item.chatCardType, item.chatFlowMessageId, item.label, true)
            }
            key={`${item.label}-summary-item`}
            editable={isEditable(item.chatCardType)}
          >
            {item.value}
          </Summary>
        ))}
        {isCurrentMessage && sendOptionValue && (
          <Button
            id="summarySend"
            onClick={() => this.submit(sendOptionValue)}
            customStyle={{ marginBottom: "16px", marginTop: "16px" }}
            disableOnClick
          >
            {sendOptionText}
          </Button>
        )}
        {isCurrentMessage && cancelOptionValue && (
          <Button
            id="summaryCancel"
            onClick={() => this.submit(cancelOptionValue)}
            customStyle={{ marginBottom: "16px" }}
            disableOnClick
            variant="textDanger"
          >
            {cancelOptionText}
          </Button>
        )}
        <Modal showing={modalShowing} setShowing={() => this.setModalShowing("", -1, "", false)}>
          <Text>{label}</Text>
          {this.renderEditCard()}
        </Modal>
      </Fragment>
    );
  }
}

export default SummaryCard;
