import React from "react";
import "add-to-calendar-button";
import moment from "moment";

import { Box } from "../../layout";

import styles from "./index.module.scss";

interface Props {
  title?: string;
  description?: string;
  location?: string;
  startTime?: string;
  endTime?: string;
}

const AddToCalendarButton: React.FC<Props> = (props) => {
  const { title = "", description = "", location = "", startTime = "", endTime = "" } = props;

  const startDate = moment.utc(startTime).format("YYYY-MM-DD");
  const atcbStartTime = moment.utc(startTime).format("HH:mm");
  const atcbEndTime = moment.utc(endTime).format("HH:mm");

  return (
    <div>
      <div className={styles.Container}>
        <Box>
          <add-to-calendar-button
            name={title}
            description={description}
            label="Add to calendar"
            startDate={startDate}
            startTime={atcbStartTime}
            endTime={atcbEndTime}
            location={location}
            customCss="/atcb.css"
            buttonStyle="custom"
            trigger="click"
            iCalFileName={location}
            hideIconButton
            hideBranding
            hideIconList
            hideIconModal
            hideCheckmark
          />
        </Box>
      </div>
    </div>
  );
};

AddToCalendarButton.displayName = "AddToCalendarButton";

export default AddToCalendarButton;
