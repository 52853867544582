import React from "react";

import { Link as LinkComponent } from "../../ui";

import { JsonContentNodeTypes, LinkNode } from "../../../types";

type PropsType = {
  node: LinkNode;
};

const Link = ({ node }: PropsType) => {
  if (node.type === JsonContentNodeTypes.link) {
    return (
      <LinkComponent customStyle={{ marginBottom: "16px" }}>
        <a href={node.properties.url} target="__blank">
          {node.properties.label}
        </a>
      </LinkComponent>
    );
  }
  return null;
};

export default Link;
