/* Address Input
======================================= */

import React from "react";

import { Input } from "../../../ui";

/* Props
--------------------------------------- */

interface Props {
  name?: string;
  placeholder?: string;
  label?: string;
  customStyle?: React.CSSProperties;
  updateResponse?: (userResponse: string) => void;
}

/* Component
--------------------------------------- */

const AddressInput: React.FC<Props> = (props: Props) => {
  const ref = React.createRef<HTMLInputElement>();

  const {
    name = "address",
    placeholder = "",
    label = "",
    updateResponse = undefined,
    customStyle = {}
  } = props;

  return (
    <div style={customStyle}>
      <Input
        name={name}
        placeholder={placeholder}
        label={label}
        updateResponse={updateResponse}
        maxRows={1}
        inputRef={ref}
      />
    </div>
  );
};

AddressInput.displayName = "AddressInput";

export default AddressInput;
