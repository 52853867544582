/* ChatLoader
======================================= */

import React from "react";
import { Avatar, Loader } from "..";
import { Logo } from "../Icons";

import styles from "./index.module.scss";

const ChatLoader = () => {
  return (
    <div className={styles.Loader}>
      <Avatar>
        <Logo size={18} />
      </Avatar>
      <Loader size={32} />
    </div>
  );
};

export default ChatLoader;
