/* Main
======================================= */

import React from "react";

import styles from "./index.module.scss";

const Main: React.FC<{ children: React.ReactNode; customStyle?: React.CSSProperties }> = ({
  children,
  customStyle
}) => {
  return (
    <div className={styles.Container} style={customStyle}>
      {children}
    </div>
  );
};

Main.displayName = "Main";

export default Main;
