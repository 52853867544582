export const userInputHasEnoughDigits = (dateString: string, format: string) => {
  const charactersMatches = format?.match(/[A-Za-z]/g);
  const charactersMatchLength = charactersMatches ? charactersMatches.length : 0;

  const numberMatches = dateString?.match(/\d/g);
  const matchedLength = numberMatches ? numberMatches.length : 0;

  return matchedLength === charactersMatchLength;
};

export const isValidDateFormat = (dateString: string, format: string) => {
  if (!userInputHasEnoughDigits(dateString, format)) return true;

  const formatParts = format.split("-");
  const dateParts = dateString.split("-");

  const yearIndex = formatParts.indexOf("YYYY");
  const monthIndex = formatParts.indexOf("MM");
  const dayIndex = formatParts.indexOf("DD");

  const year = Number(dateParts[yearIndex]);
  const month = Number(dateParts[monthIndex]);
  const day = Number(dateParts[dayIndex]);

  const date = new Date(year, month - 1, day);

  return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
};
