import React, { useContext } from "react";
import styles from "./index.module.scss";

import { VariantContext, VariantTypes } from "./Steps";

interface Props {
  number: number | string;
  children?: React.ReactNode;
}

const Step: React.FC<Props> = (props) => {
  const variant = useContext<VariantTypes>(VariantContext);

  // Construct the variant class name safely
  const variantClass = variant ? styles[`step-${variant}`] : "";

  return (
    <div className={`${styles.step} ${variantClass}`}>
      <div className={styles.number}>{variant !== "bullet" ? props.number : ""}</div>
      <div className={styles.line} />
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};

Step.displayName = "Step";

export default Step;
