/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";

type IconButtonProps = {
  icon: React.FC;
  onClick?: (...props: any) => void;
  href?: string;
  target?: "_blank";
};

const IconButton: React.FC<IconButtonProps> = ({ href, target, icon: Icon, onClick }) => {
  return (
    <a
      style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
      href={href}
      target={target}
      onClick={onClick}
    >
      <Icon />
    </a>
  );
};

export default IconButton;
