/* Select Button
======================================= */

import React from "react";
import cx from "classnames";

import { Text } from "../../ui";
import { ChevronRight } from "../Icons";

import styles from "./index.module.scss";

/* Props
--------------------------------------- */

interface Props {
  text: string;
  active?: boolean;
  customStyle?: React.CSSProperties;
  onClick?: (event: React.MouseEvent) => void;
}

/* Component
--------------------------------------- */

const SelectButton: React.FC<Props> = (props: Props) => {
  const onClick = (e: React.MouseEvent) => {
    if (props.onClick) props.onClick(e);
  };
  const { active = false, text, customStyle = {} } = props;
  const isActive = active ? "isActive" : undefined;

  return (
    <button
      type="button"
      className={cx(styles.Container, { [styles.isActive]: isActive })}
      onClick={(e: React.MouseEvent<Element, MouseEvent>) => onClick(e)}
      style={customStyle}
    >
      <Text as="span" customStyle={{ marginRight: "10px" }}>
        {text}
      </Text>
      <ChevronRight />
    </button>
  );
};

SelectButton.displayName = "SelectButton";

export default SelectButton;
