import React from "react";
import { connect } from "react-redux";

import { Box } from "../../../layout";
import { Card, Text, Button } from "../..";
import JsonContentRenderEngine from "../../../JsonContentRenderEngine";

import { fetchNextMessage as fetchNextMessageAction } from "../../../../actions";

import { ChatFlowTags } from "../../../../constants";
import getChatType from "../../../../utils/getChatType";
import { getDefaultLandingMessage } from "./defaultLandingMessages";

import styles from "./index.module.scss";

const { APPOINTMENT_DETAILS, SECURE_DIRECT_MESSAGE } = ChatFlowTags;

interface Props {
  chatInfo?: ChatInfo;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  // toggleDisplayLanding: (displayLanding: boolean) => void;
}
const getStartChatLabel = (isConversationComplete: boolean, chatType?: string) => {
  if (isConversationComplete) {
    return "Completed";
  }

  return chatType === SECURE_DIRECT_MESSAGE ? "View Message" : "Get started";
};

const InfoTasksCardDeprecated: React.FC<Props> = ({ chatInfo, fetchNextMessage }: Props) => {
  const {
    isConversationComplete,
    organizationName,
    landingMessage: chatInfoLandingMessage,
    chatFlowTags
  } = chatInfo as ChatInfo;

  const chatType = getChatType(chatFlowTags) || undefined;

  const landingMessage =
    chatInfoLandingMessage ||
    (chatType && (getDefaultLandingMessage(organizationName, chatType) as Message));
  // TODO: Make custom card PayloadOptions type
  const contentJSON =
    landingMessage &&
    landingMessage.payloadOptions &&
    (landingMessage.payloadOptions as BasePayloadOptions).contentJson
      ? (landingMessage.payloadOptions as BasePayloadOptions).contentJson
      : null;

  let header;
  let paragraphs: string[] = [];

  if (!contentJSON) {
    const content =
      landingMessage && typeof landingMessage.payloadContent === "string"
        ? landingMessage.payloadContent
        : "";

    if (content) {
      try {
        const parsedContent = JSON.parse(content);
        header = parsedContent.header || header;
        paragraphs = parsedContent.paragraphs || paragraphs;
      } catch (error) {
        console.error("Failed to parse landing card content", error);
      }
    }
  }

  const showDeprecatedContent = Boolean(header || (paragraphs && paragraphs.length));
  const showContentJSON = Boolean(contentJSON);
  const showCallToAction = !chatFlowTags.includes(APPOINTMENT_DETAILS);

  if (!showDeprecatedContent && !showContentJSON && !showCallToAction) return null;

  return (
    <div className={styles.InfoTaskCard}>
      <Card customStyle={{ padding: "16px 16px 4px", marginBottom: "24px" }} boxShadow>
        {showDeprecatedContent && (
          <>
            {/* DEPRECATED Heading */}
            {header && (
              <Text variant="titleSmall" bold customStyle={{ paddingBottom: "16px" }}>
                {header}
              </Text>
            )}
            {/* DEPRECATED Paragraphs */}
            {paragraphs && paragraphs.length > 0 && (
              <Box customStyle={{ width: "100%", paddingBottom: "16px" }}>
                <Text variant="small">
                  {paragraphs.map((paragraph, i) => {
                    // eslint-disable-next-line react/no-array-index-key
                    return <p key={i}>{paragraph}</p>;
                  })}
                </Text>
              </Box>
            )}
          </>
        )}

        {/* Landing Card Content JSON */}
        {showContentJSON && (
          <JsonContentRenderEngine
            content={contentJSON}
            contentBoxShadow={false}
            contentPadding="0px"
            contentMargin="0px"
          />
        )}

        {/* Start Chat Call-To-Action */}
        {showCallToAction && (
          <Box customStyle={{ width: "100%", padding: "16px 0px" }}>
            <Button
              id="landingPageBtn"
              variant={isConversationComplete ? "success" : undefined}
              disabled={isConversationComplete}
              onClick={() => {
                if (chatInfo?.conversationId && chatInfo?.landingMessage?.id) {
                  fetchNextMessage({
                    messageTemplateId: chatInfo?.landingMessage?.id,
                    userResponse: null,
                    sessionId: chatInfo?.sessionId,

                    lastReceivedMessageId: null, // TODO: can we remove this from required FetchNextMessageParams?
                    chatFlowId: null // TODO: can we remove this from required FetchNextMessageParams?
                  });
                }
              }}
            >
              {getStartChatLabel(isConversationComplete, chatType)}
            </Button>
          </Box>
        )}
      </Card>
    </div>
  );
};

export default connect(null, { fetchNextMessage: fetchNextMessageAction })(InfoTasksCardDeprecated);
