/* Text Card
======================================= */

import React from "react";
import TextCard from "./TextCard";
import { PhoneInput, Button, SkipButton } from "..";
import { Logo } from "../Icons";
import moment from "moment";
import { ChatCardTypes } from "../../../constants";

/* Props
--------------------------------------- */

interface PhoneInputProps {
  msg: Message;
  index: number;
  isCurrentMessage: boolean;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  editMode?: boolean;
  save?: (userResponse: string) => Promise<void>;
}

interface TextInputState {
  userResponse: string;
}

/* Component
--------------------------------------- */
class PhoneInputCard extends React.Component<PhoneInputProps, TextInputState> {
  constructor(props: PhoneInputProps) {
    super(props);

    this.state = {
      userResponse: ""
    };
  }

  updateResponse = (userResponse: string) => {
    if (userResponse.replace(/\D/g, "").length < 11) {
      // react-mask-input allows an extra character to be input after the mask is full!
      this.setState({ userResponse });
    }
  };

  submit = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    const { userResponse } = this.state;
    const { fetchNextMessage, save } = this.props;

    const cleanUserResponse = userResponse.replace(/x/g, "");
    if (save) {
      return save(cleanUserResponse);
    }

    return fetchNextMessage({
      lastReceivedMessageId: null,
      userResponse: cleanUserResponse,
      userResponseType: ChatCardTypes.PHONE_INPUT,
      chatFlowId: null,
      showUserResponse: true,
      createdAt: moment().toDate()
    });
  };

  render() {
    const { msg, index, isCurrentMessage, editMode } = this.props;
    const { userResponse } = this.state;
    const { payloadContent } = msg;
    // TODO: Make custom card PayloadOptions type
    const payloadOptions = msg?.payloadOptions as BasePayloadOptions;
    const skippable = payloadOptions?.skippable || false;

    return (
      <form>
        <TextCard
          msg={msg}
          index={index}
          avatar={<Logo size={18} />}
          customStyle={{ marginBottom: "16px" }}
          editMode={editMode}
        >
          <>{payloadContent}</>
        </TextCard>
        {isCurrentMessage && (
          <PhoneInput
            id="phoneInput"
            name="message"
            customStyle={{ marginBottom: "20px" }}
            updateResponse={this.updateResponse}
          />
        )}
        {isCurrentMessage && (
          <Button
            id="phoneSendBtn"
            type="submit"
            onClick={this.submit}
            disabled={userResponse.replace(/\D/g, "").length < 10}
            disableOnClick
          >
            Send
          </Button>
        )}
        {isCurrentMessage && !editMode && skippable && <SkipButton index={index} message={msg} />}
      </form>
    );
  }
}

export default PhoneInputCard;
