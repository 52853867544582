/* Scale Input Card
======================================= */

import React, { useState } from "react";

import TextCard from "./TextCard";
import { Button, ScaleChoice, SkipButton } from "..";
import moment from "moment";
import { ChatCardTypes } from "../../../constants";
import { Logo } from "../Icons";

import styles from "./index.module.scss";

/* Props
--------------------------------------- */

interface ScaleInputProps {
  msg: Message;
  index: number;
  isCurrentMessage: boolean;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  editMode?: boolean;
}

/* Component
--------------------------------------- */

const ScaleInputCard: React.FC<ScaleInputProps> = ({
  msg,
  index,
  isCurrentMessage,
  editMode = false,
  fetchNextMessage
}: ScaleInputProps) => {
  const [selectedValue, updateSelectedValue] = useState("");

  const payloadOptions = msg?.payloadOptions as ScaleInputPayloadOptions;
  const skippable = payloadOptions?.skippable || false;

  const answers = payloadOptions.answers || [];

  const submit = () => {
    const userResponse = selectedValue
      ? (answers.find((answer) => answer.value === selectedValue) as ScaleInputOption)
      : null;

    fetchNextMessage({
      lastReceivedMessageId: null,
      userResponse,
      userResponseType: ChatCardTypes.SCALE_INPUT,
      chatFlowId: null,
      showUserResponse: true,
      createdAt: moment().toDate()
    });
  };

  const selectOption = (option: ScaleInputOption) => {
    updateSelectedValue(option.value);
  };

  return (
    <div>
      <TextCard
        msg={msg}
        index={index}
        avatar={<Logo size={18} />}
        customStyle={{ marginBottom: "16px" }}
        editMode={editMode}
      />
      {isCurrentMessage && (
        <div className={styles.ScaleInputCard}>
          {answers.map((option) => (
            <ScaleChoice
              key={option.value}
              option={option}
              onClick={() => selectOption(option)}
              isActive={option.value === selectedValue}
            />
          ))}
        </div>
      )}
      {isCurrentMessage && !editMode && (
        <Button onClick={submit} disabled={selectedValue === ""} disableOnClick>
          Send
        </Button>
      )}
      {isCurrentMessage && !editMode && skippable && <SkipButton index={index} message={msg} />}
    </div>
  );
};

ScaleInputCard.displayName = "ScaleInputCard";

export default ScaleInputCard;
