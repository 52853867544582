import React from "react";

type PropsType = {
  color?: string;
};

const DeleteIcon: React.FC<PropsType> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
      <defs>
        <clipPath id="clip-path">
          <path
            id="Combined_Shape"
            data-name="Combined Shape"
            d="M5,22a3,3,0,0,1-3-3V6H1A1,1,0,0,1,1,4H5V3A3,3,0,0,1,8,0h4a3,3,0,0,1,3,3V4h4a1,1,0,0,1,0,2H18V19a3,3,0,0,1-3,3ZM4,19a1,1,0,0,0,1,1H15a1,1,0,0,0,1-1V6H4ZM13,4V3a1,1,0,0,0-1-1H8A1,1,0,0,0,7,3V4ZM11,16V10a1,1,0,1,1,2,0v6a1,1,0,1,1-2,0ZM7,16V10a1,1,0,1,1,2,0v6a1,1,0,1,1-2,0Z"
            fill={color || "#494b50"}
          />
        </clipPath>
      </defs>
      <g id="Icons_trash-2" data-name="Icons/trash-2" transform="translate(-2 -1)">
        <g id="Group_43" data-name="Group 43" transform="translate(2 1)">
          <path
            id="Combined_Shape-2"
            data-name="Combined Shape"
            d="M5,22a3,3,0,0,1-3-3V6H1A1,1,0,0,1,1,4H5V3A3,3,0,0,1,8,0h4a3,3,0,0,1,3,3V4h4a1,1,0,0,1,0,2H18V19a3,3,0,0,1-3,3ZM4,19a1,1,0,0,0,1,1H15a1,1,0,0,0,1-1V6H4ZM13,4V3a1,1,0,0,0-1-1H8A1,1,0,0,0,7,3V4ZM11,16V10a1,1,0,1,1,2,0v6a1,1,0,1,1-2,0ZM7,16V10a1,1,0,1,1,2,0v6a1,1,0,1,1-2,0Z"
            fill={color || "#494b50"}
          />
        </g>
      </g>
    </svg>
  );
};

export default DeleteIcon;
