/* EndCard
======================================= */

import React from "react";

import { Text, TextCard, Button } from "..";
import { Box } from "../../layout";
import { CircleCheck } from "../Icons";

import styles from "./index.module.scss";
import theme from "../../../theme/default";

/* Props
---------------------------------------*/

interface Props {
  msg: Message;
  endChat?: () => void;
}

/* Component
--------------------------------------- */

const EndCard: React.FC<Props> = ({ msg, endChat }) => {
  let content;
  let headerContent;
  let bodyContent;
  // Sometimes we'll send JSON if there are different components to display
  try {
    content = typeof msg.payloadContent === "string" ? msg.payloadContent : "";
    content = JSON.parse(content);
    headerContent = content.headerContent;
    bodyContent = content.bodyContent;
  } catch (err) {
    headerContent = msg.payloadContent;
  }

  return (
    <div id="endCard" className={styles.EndCard}>
      <Box customStyle={{ marginBottom: "20px" }} className="icon">
        <CircleCheck size={72} color={theme.colors.icon.primary} />
      </Box>
      <Text variant="large" bold customStyle={{ marginBottom: "16px" }}>
        {headerContent}
      </Text>
      <TextCard
        customStyle={{ marginBottom: "20px" }}
        index={1}
        variant="small"
        centerLink
        msg={{
          cardType: "text",
          payloadContent: bodyContent,
          payloadOptions: msg.payloadOptions,
          sender: "mikata",
          chatFlowMessageId: 1
        }}
      />
      {endChat && (
        <Button
          id="endBtn"
          onClick={() => {
            endChat();
          }}
          disableOnClick
        >
          Done
        </Button>
      )}
    </div>
  );
};

EndCard.displayName = "EndCard";

export default EndCard;
