import React, { useEffect, useState } from "react";
import cx from "classnames";

import JsonContentRenderEngine from "../../../../JsonContentRenderEngine";

import { ChevronRight, Edit } from "../../../Icons";
import { Text, Link, Button } from "../../../../ui";
import { PatientConsentStatus, RecordingSessionStatus } from "../../../../../types";

import ConsentForm from "./ConsentForm";
import useWebSocketConnection from "../useWebSocketConnection";

import styles from "./index.module.scss";

type InfoTermsConsentProps = {
  msg: Message;
  isCurrentMessage: boolean;
  undoUserResponse: () => void;
};

const getConsentHeading = (patientConsentStatus?: PatientConsentStatus) => {
  switch (patientConsentStatus) {
    case PatientConsentStatus.ACCEPTED: {
      return "You have consented to use Mika for this appointment";
    }
    case PatientConsentStatus.DENIED: {
      return "You have declined to use Mika for this appointment";
    }
    default: {
      return "Consent";
    }
  }
};

const InfoTermsConsent = ({ msg, isCurrentMessage, undoUserResponse }: InfoTermsConsentProps) => {
  const { recording, editPatientConsent, patientConsentLoading } = useWebSocketConnection();

  const [activeStep, setActiveStep] = useState<number>(
    // eslint-disable-next-line no-nested-ternary
    !recording ? 1 : recording.patientConsentStatus !== PatientConsentStatus.PENDING ? 4 : 1
  );

  const [infoOpen, setInfoOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);

  const consentOpen =
    recording &&
    recording.patientConsentStatus === PatientConsentStatus.PENDING &&
    isCurrentMessage;

  useEffect(() => {
    if (!recording && activeStep !== 1) {
      setActiveStep(1);
    }

    if (
      recording &&
      recording.patientConsentStatus !== PatientConsentStatus.PENDING &&
      activeStep !== 4
    ) {
      setActiveStep(4);
    }
  }, [recording]);

  return (
    <div className={styles.Card}>
      {/* Step 1 */}
      <div
        className={cx(styles.CardSection, {
          [styles.CardSectionOpen]: infoOpen || activeStep === 1
        })}
      >
        <button
          type="button"
          className={styles.CardSectionHeader}
          onClick={() => {
            setInfoOpen((open) => !open);
          }}
          disabled={activeStep === 1}
        >
          <span className={styles.CircleNumber}>
            <Text textColor="white" bold>
              1
            </Text>
          </span>
          <Text variant="titleSmall" bold textAlign="left">
            About Mika
          </Text>
          {activeStep !== 1 && (
            <div
              className={cx(styles.ToggleButtonIcon, {
                [styles.ToggleButtonIconOpen]: infoOpen
              })}
            >
              <ChevronRight size={24} />
            </div>
          )}
        </button>
        <div className={cx(styles.Content, { [styles.ContentOpen]: infoOpen || activeStep === 1 })}>
          <JsonContentRenderEngine
            content={(msg.payloadOptions as ScribeVisitCardPayloadOptions)?.information}
            contentBoxShadow={false}
            contentPadding="0px"
            contentMargin="0px"
          />
        </div>
      </div>

      {/* Step 2 */}
      {activeStep >= 2 && (
        <div
          className={cx(styles.CardSection, {
            [styles.CardSectionOpen]: termsOpen || activeStep === 2
          })}
        >
          <button
            type="button"
            className={styles.CardSectionHeader}
            onClick={() => {
              setTermsOpen((open) => !open);
            }}
            disabled={activeStep === 2}
          >
            <span className={styles.CircleNumber}>
              <Text textColor="white" bold>
                2
              </Text>
            </span>
            <Text variant="titleSmall" bold textAlign="left">
              Privacy &amp; Security
            </Text>
            {activeStep !== 2 && (
              <div
                className={cx(styles.ToggleButtonIcon, {
                  [styles.ToggleButtonIconOpen]: termsOpen
                })}
              >
                <ChevronRight size={24} />
              </div>
            )}
          </button>
          <div
            className={cx(styles.Content, { [styles.ContentOpen]: termsOpen || activeStep === 2 })}
          >
            <Text variant="medium" customStyle={{ marginBottom: "16px" }}>
              We take your privacy and security seriously and comply with all applicable regulations
              to protect your information. However, when information is transmitted over the
              internet, privacy and security can never be fully guaranteed.
            </Text>
            <Text variant="medium" customStyle={{ marginBottom: "4px" }}>
              Please see our privacy policy for more details about how your information is
              collected, used and stored.
            </Text>
            <Link customStyle={{ marginBottom: "16px" }}>
              <a
                href="https://mikatahealth.com/privacy_policy"
                target="_blank"
                rel="noopener noreferrer"
                tabIndex={-9999}
              >
                Privacy Policy
              </a>
            </Link>
            <Text variant="medium" bold customStyle={{ marginBottom: "8px" }}>
              It&apos;s your choice
            </Text>
            <Text variant="medium" customStyle={{ marginBottom: "16px" }}>
              You control whether or not your provider can use Mika during your appointment by
              providing your consent below.
            </Text>
            <Text variant="medium" customStyle={{ marginBottom: "16px" }}>
              Your provider will offer you the best care and experience possible during your
              appointment, even if you do not consent to using Mika.
            </Text>
          </div>
        </div>
      )}

      {/* Step 3 */}
      {activeStep >= 3 && (
        <div className={cx(styles.CardSection, { [styles.CardSectionOpen]: true })}>
          <button
            type="button"
            className={styles.CardSectionHeader}
            onClick={() => {
              editPatientConsent();
            }}
            disabled={activeStep === 3}
          >
            <span className={styles.CircleNumber}>
              <Text textColor="white" bold>
                3
              </Text>
            </span>
            <Text variant="titleSmall" bold textAlign="left">
              {getConsentHeading(recording?.patientConsentStatus)}
            </Text>
            {!consentOpen &&
              recording &&
              !recording.sessionCompleted &&
              recording.patientConsentStatus === PatientConsentStatus.ACCEPTED &&
              recording.status === RecordingSessionStatus.PENDING && (
                <div className={styles.ToggleButtonIcon}>
                  <Edit size={24} />
                </div>
              )}
          </button>

          {!isCurrentMessage &&
            recording &&
            recording.patientConsentStatus === PatientConsentStatus.DENIED &&
            recording.status === RecordingSessionStatus.PENDING && (
              <div className={styles.UpdateConsentButton}>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    undoUserResponse();
                    editPatientConsent();
                  }}
                  disabled={patientConsentLoading}
                >
                  Change my consent
                </Button>
              </div>
            )}
          <div
            className={cx(styles.Content, {
              [styles.ContentOpen]: consentOpen || activeStep === 3
            })}
          >
            {(consentOpen || activeStep === 3) && <ConsentForm disabled={!isCurrentMessage} />}
          </div>
        </div>
      )}
      {activeStep < 3 && (
        <div className={styles.NextStepButton}>
          <Button
            onClick={() => {
              setActiveStep((currentStep) => currentStep + 1);
            }}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

export default InfoTermsConsent;
