/* eslint-disable react/prefer-stateless-function */

import React from "react";
import { Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import { HelmetProvider } from "react-helmet-async";

import { Viewport } from "./components/layout";
import DeprecatedChatSessionManager from "./components/providers/DeprecatedChatSessionManager";
import ChatSessionManager from "./components/providers/ChatSessionManager";
import { NotificationOverlay } from "./components/ui";

import { ChatContainer, DeprecatedChatContainer, Home } from "./pages";
import AnalyticsProvider from "./components/providers/AnalyticsProvider";
import ErrorBoundary from "./components/ErrorBoundary";

type AppProps = any;

class App extends React.Component<AppProps, any> {
  render() {
    const { locationName, organizationName, secondaryColour } = this.props;
    const metaTitle = `${locationName || organizationName || "Mikata Health"}`;
    const metaDescription = "Your clinic uses Mikata Health to enhance your care.";
    const metaImage = "https://mikatahealth.com/favicon-32x32.png";

    return (
      <>
        <HelmetProvider>
          <title>{metaTitle}</title>
          <meta name="theme-color" content={secondaryColour || "#000000"} />
          <meta name="description" content={metaDescription} />
          <meta property="og:locale" content="en_CA" />
          <meta property="og:site_name" content={metaTitle} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:image" content={metaImage} />
          <meta property="og:image:secure_url" content={metaImage} />
        </HelmetProvider>
        <Viewport>
          <Routes>
            <Route
              path="/chat/:token/messages"
              element={
                <DeprecatedChatSessionManager>
                  <AnalyticsProvider>
                    <ErrorBoundary>
                      <DeprecatedChatContainer />
                    </ErrorBoundary>
                  </AnalyticsProvider>
                </DeprecatedChatSessionManager>
              }
            />
            <Route
              path="/:shortCode"
              element={
                <>
                  <NotificationOverlay />
                  <ChatSessionManager>
                    <AnalyticsProvider>
                      <ErrorBoundary>
                        <ChatContainer />
                      </ErrorBoundary>
                    </AnalyticsProvider>
                  </ChatSessionManager>
                </>
              }
            />
            <Route path="/*" element={<Home />} />
          </Routes>
        </Viewport>
      </>
    );
  }
}

const mapStateToProps = ({ chatInfo }: ReduxState) => {
  return {
    locationName: chatInfo.locations.length > 0 ? chatInfo.locations[0].locationName : "",
    organizationName: chatInfo.organizationName || "",
    logo: chatInfo.logo,
    secondaryColour: chatInfo.secondaryColour
  };
};

const connected = connect(mapStateToProps, null)(App);

export default connected;
