export type Option = { label: string; value: string | number };

export const styles: (props: { hasError: boolean }) => any = ({ hasError }) => ({
  container: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
    height: "48px",
    opacity: state.isDisabled ? 0.5 : 1
  }),
  control: (provided: any) => {
    return {
      ...provided,
      backgroundColor: hasError ? "#ffb4ac" : "#f2f2f3",
      borderRadius: "8px",
      borderStyle: "none",
      fontSize: "16px",
      lineHeight: "16px",
      fontWeight: 400
    };
  },
  valueContainer: (provided: any) => ({ ...provided, padding: "10px 16px" }),
  indicatorSeparator: (provided: any) => ({ ...provided, display: "none" }),
  placeholder: (provided: any) => ({ ...provided, margin: "0", color: "#94979e" }),
  singleValue: (provided: any) => ({ ...provided, margin: "0" }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f1f7fd" : "#ffffff",
    color: state.isSelected ? "#4072de" : "#1d2029",
    fontWeight: state.isSelected ? 700 : 400,
    padding: "12px 30px"
  }),
  menu: (provided: any) => {
    return {
      ...provided,
      borderRadius: "8px",
      marginTop: "0",
      overflow: "hidden"
    };
  },
  menuList: (provided: any) => {
    return { ...provided, padding: "8px 0", maxHeight: "200px" };
  }
});
