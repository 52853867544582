import React, { useRef, useEffect } from "react";
import moment from "moment-timezone";
import cx from "classnames";

import { Text } from "../..";
import { ChevronLeft, ChevronRight } from "../../Icons";

import styles from "./index.module.scss";
import theme from "../../../../theme/default";

export type WeekData = {
  startTimeUTC: Date;
  endTimeUTC: Date;
  datesLocal: Array<{
    id: string;
    day: string;
    dayOfWeek: string;
    monthName: string;
  }>;
};
export type CalendarDays = Array<WeekData>;

type WeekPropsType = {
  weekData: WeekData;
  availableAppointments: AvailableAppointments;
  focusedDay: string;
  onDaySelection: (selectedDay: string) => void;
  getAvailableAppointmentsForWeek: (weekData: WeekData) => void;
};

const Week = ({
  weekData,
  availableAppointments,
  focusedDay,
  onDaySelection,
  getAvailableAppointmentsForWeek
}: WeekPropsType) => {
  const weekRef = useRef<any>(null);
  const isLoading =
    !availableAppointments ||
    !!(weekData.datesLocal[0].id && !availableAppointments[weekData.datesLocal[0].id]);
  const handleIntersectionEvent: IntersectionObserverCallback = (entries) => {
    const isVisible = entries[0]?.isIntersecting;

    if (isVisible && isLoading) {
      getAvailableAppointmentsForWeek(weekData);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersectionEvent, {});

    if (weekRef.current) {
      observer.observe(weekRef.current);
    }

    return () => {
      if (weekRef.current) {
        observer.unobserve(weekRef.current);
      }
    };
  }, [weekRef, isLoading]);

  return (
    <div key={weekData.startTimeUTC.toISOString()} ref={weekRef} className={styles.Week}>
      {weekData.datesLocal.map((dateInfo) => {
        const isSelected = focusedDay && focusedDay === dateInfo.id;
        const hasAppointments =
          availableAppointments[dateInfo.id] && availableAppointments[dateInfo.id].length > 0;
        return (
          <button
            type="button"
            key={dateInfo.id}
            className={cx(styles.Day, {
              [styles.DaySelected]: isSelected
            })}
            onClick={() => onDaySelection(dateInfo.id)}
          >
            {dateInfo.day === "1" && (
              <Text className={styles.MonthLabel} variant="meta" textColor={theme.colors.black}>
                {dateInfo.monthName}
              </Text>
            )}
            <Text variant="meta" textColor={isSelected ? theme.colors.white : theme.colors.grey[3]}>
              {dateInfo.dayOfWeek}
            </Text>
            {!isLoading && (
              <Text
                variant="small"
                textColor={
                  // eslint-disable-next-line no-nested-ternary
                  isSelected
                    ? theme.colors.white
                    : hasAppointments
                      ? theme.colors.black
                      : theme.colors.grey[2]
                }
              >
                {dateInfo.day}
              </Text>
            )}
          </button>
        );
      })}
    </div>
  );
};

interface PropsType {
  focusedDay: string;
  calendarDays: CalendarDays;
  availableAppointments: AvailableAppointments;
  onDaySelection: (selectedDay: string) => void;
  getAvailableAppointmentsForWeek: (weekData: WeekData) => void;
}

const getDaysArray = (start: Date, end: Date): Array<Date> => {
  const dates = [];
  const dt = new Date(start);

  while (dt <= end) {
    dates.push(new Date(dt));
    dt.setDate(dt.getDate() + 1);
  }
  return dates;
};

const addDaysToDate = (
  numberOfDays: number,
  timeOfDay: "start" | "now" | "end",
  timezone = "Canada/Mountain"
): Date => {
  switch (timeOfDay) {
    case "start":
      return moment().tz(timezone).add(numberOfDays, "days").startOf("day").toDate();
    case "now":
      return moment().tz(timezone).add(numberOfDays, "days").toDate();
    case "end":
      return moment().tz(timezone).add(numberOfDays, "days").endOf("day").toDate();
    default:
      return moment().tz(timezone).toDate();
  }
};

export const generateCalendarDays = (
  organizationTimezone = "Canada/Mountain",
  availabilityRangeEndWeeks: number
) => {
  const weeks = [...Array(availabilityRangeEndWeeks).keys()].map((weekIndex) => ({
    startTimeUTC: addDaysToDate(
      7 * weekIndex + (weekIndex === 0 ? 0 : 1),
      weekIndex === 0 ? "now" : "start",
      organizationTimezone
    ), // 0, 8, 15...
    endTimeUTC: addDaysToDate(7 * (weekIndex + 1), "end", organizationTimezone) // 7, 14, 21...
  }));
  return weeks.map((weekData) => {
    return {
      ...weekData,
      datesLocal: getDaysArray(weekData.startTimeUTC, weekData.endTimeUTC).map((date: Date) => {
        const orgTimezoneDate = moment.tz(date, organizationTimezone);
        return {
          id: orgTimezoneDate.format("YYYY-MM-DD"),
          day: orgTimezoneDate.format("D"),
          dayOfWeek: orgTimezoneDate.format("ddd"),
          monthName: orgTimezoneDate.format("MMMM")
        };
      })
    };
  });
};

const DayPicker = ({
  focusedDay,
  calendarDays,
  availableAppointments,
  onDaySelection,
  getAvailableAppointmentsForWeek
}: PropsType) => {
  const calendarRef = useRef<any>(null);

  const scrollCalendar = (direction: "left" | "right") => {
    if (calendarRef.current) {
      const nextScrollLeft =
        direction === "left"
          ? calendarRef.current.scrollLeft - 100
          : calendarRef.current.scrollLeft + 100;

      calendarRef.current.scrollTo({
        left: nextScrollLeft,
        behavior: "smooth"
      });
    }
  };

  return (
    <div className={styles.DayPicker}>
      <button
        type="button"
        className={cx(styles.ScrollButton, styles.ScrollButtonLeft)}
        onClick={() => scrollCalendar("left")}
      >
        <ChevronLeft />
      </button>
      <div
        className={styles.Calendar}
        ref={(ref) => {
          if (ref) {
            calendarRef.current = ref;
          }
        }}
      >
        {calendarDays.map((weekData) => {
          return (
            <Week
              key={weekData.startTimeUTC.toISOString()}
              weekData={weekData}
              focusedDay={focusedDay}
              onDaySelection={onDaySelection}
              availableAppointments={availableAppointments}
              getAvailableAppointmentsForWeek={getAvailableAppointmentsForWeek}
            />
          );
        })}
      </div>
      <button
        type="button"
        className={cx(styles.ScrollButton, styles.ScrollButtonRight)}
        onClick={() => scrollCalendar("right")}
      >
        <ChevronRight />
      </button>
    </div>
  );
};

export default DayPicker;
