/* Icon
======================================= */

import React from "react";
import cx from "classnames";

import { getThemeIcons, AllThemeIconTypes, get } from "../../../utils/getThemeIcons";

import styles from "./index.module.scss";
import theme from "../../../theme/default";

interface Props {
  name: AllThemeIconTypes;
  size?: string;
  as?: "div" | "span";
  error?: boolean;
  iconColor?: string;
  customStyle?: React.CSSProperties;
}

const IconBase: React.FC<Props> = ({
  size = "24px",
  customStyle = {},
  as,
  name,
  error,
  iconColor
}) => {
  const icons = getThemeIcons(theme.themeName as ThemeName);

  let color = iconColor;

  if (iconColor && typeof iconColor === "string" && iconColor.indexOf(".") !== -1) {
    color = get(theme.colors, iconColor) || theme.colors.icon.primary;
  }

  if (error) {
    color = theme.colors.error.text;
  }

  const dynamicStyleProp = {
    "--size": size,
    "--fill-color": color || "currentColor"
  } as React.CSSProperties;

  const style = {
    ...customStyle,
    ...dynamicStyleProp
  };
  if (as === "span") {
    return (
      <span id="iconBase" style={style} className={styles.dynamicFill}>
        {icons[name]}
      </span>
    );
  }

  return (
    <div
      id="iconBase"
      style={style}
      className={cx(styles.SVGContainer, { [styles.DynamicFill]: name !== "logo" })}
    >
      {icons[name]}
    </div>
  );
};

const Icon = IconBase;

Icon.displayName = "Icon";

export default Icon;
