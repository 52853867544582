/* Text Card
======================================= */

import React from "react";
import TextCard from "./TextCard";
import { Input, Button, SkipButton } from "..";
import moment from "moment";
import { ChatCardTypes } from "../../../constants";
import { isPostalCode } from "../../../utils/validators/isPostalCode";
import { TextInputFormat } from "../../../types";
import { Logo } from "../Icons";

/* Props
--------------------------------------- */

interface TextInputProps {
  msg: Message;
  index: number;
  isCurrentMessage: boolean;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  editMode?: boolean;
  save?: (userResponse: string) => Promise<void>;
}

interface TextInputState {
  userResponse: string;
}

const getValidator = (msg: Message) => {
  const inputFormat = (msg.payloadOptions as BasePayloadOptions)?.inputFormat || "";
  const postalCodeMode = inputFormat === TextInputFormat.POSTAL_CODE;

  const validator = postalCodeMode ? isPostalCode() : undefined;
  return validator;
};

/* Component
--------------------------------------- */

class TextInputCard extends React.Component<TextInputProps, TextInputState> {
  constructor(props: TextInputProps) {
    super(props);

    this.state = {
      userResponse: ""
    };
  }

  updateResponse = (userResponse: string) => {
    this.setState({ userResponse });
  };

  submit = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    const { userResponse } = this.state;
    const { fetchNextMessage, save } = this.props;

    if (save) {
      return save(userResponse);
    }

    return fetchNextMessage({
      lastReceivedMessageId: null,
      userResponse,
      userResponseType: ChatCardTypes.TEXT_INPUT,
      chatFlowId: null,
      showUserResponse: true,
      createdAt: moment().toDate()
    });
  };

  render() {
    const { msg, index, isCurrentMessage, editMode } = this.props;
    const { userResponse } = this.state;
    // TODO: Make custom card PayloadOptions type
    const payloadOptions = msg.payloadOptions as BasePayloadOptions;
    const skippable = payloadOptions?.skippable || false;

    const validator = getValidator(msg);
    const hasError = Boolean(validator && validator(userResponse));
    const canSubmit = Boolean(userResponse && !hasError);

    return (
      <form>
        <TextCard
          msg={msg}
          index={index}
          avatar={<Logo size={18} />}
          customStyle={{ marginBottom: "16px" }}
          editMode={editMode}
        />
        {isCurrentMessage && (
          <Input
            id={`textInput-${index}`}
            placeholder="Type response"
            name="message"
            maxRows={5}
            updateResponse={this.updateResponse}
            value={userResponse}
            autoFocus
            validate={validator}
            customStyle={{ marginBottom: "20px" }}
          />
        )}
        {isCurrentMessage && (
          <Button
            id={`sendBtn-${index}`}
            type="submit"
            onClick={this.submit}
            disabled={!canSubmit}
            disableOnClick
          >
            Send
          </Button>
        )}
        {isCurrentMessage && !editMode && skippable && <SkipButton index={index} message={msg} />}
      </form>
    );
  }
}

export default TextInputCard;
