/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { motion } from "framer-motion";
import { Scroll } from "../../components/layout";

import styles from "./index.module.scss";

type Props = {
  header?: React.ReactNode;
  children?: React.ReactNode;
};

const animation = {
  initial: { y: "100%" },
  animate: { y: 0 },
  exit: { y: "100%" },
  transition: { type: "spring", stiffness: 200, damping: 25 }
};

const ChatWrapper: React.FC<Props> = ({ children, header }) => {
  return (
    <motion.div {...animation} className={styles.Container}>
      {header}
      <Scroll id="scrollContainer" className={styles.scroll}>
        {children}
      </Scroll>
    </motion.div>
  );
};

export default ChatWrapper;
