/* Units Input Field
======================================= */

import React, { useState } from "react";
import cx from "classnames";

import styles from "./index.module.scss";

/* Props
--------------------------------------- */
interface Props {
  numberResponse: string;
  units: string[];
  setNumberResponse: (numberResponse: string) => void;
  setSelectedUnit: (unit: string) => void;
  maxDigits: number | undefined;
  customStyle?: React.CSSProperties;
}

/* Component
--------------------------------------- */

const UnitsInput: React.FC<Props> = ({
  numberResponse,
  units,
  setNumberResponse,
  setSelectedUnit,
  maxDigits,
  customStyle = {}
}) => {
  const [isFocused, setFocus] = useState(false);
  const onFocus = () => setFocus(true);
  const onBlur = () => setFocus(false);

  return (
    <div
      className={cx(styles.Container, {
        [styles.isFocused]: isFocused
      })}
      style={customStyle}
    >
      <div className={styles.Field} onFocus={onFocus} onBlur={onBlur}>
        <input
          type="number"
          placeholder="Type response"
          value={numberResponse}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={({ target }) => {
            if (!maxDigits || target.value.length <= maxDigits) setNumberResponse(target.value);
          }}
        />

        {units.length === 1 && <span>{units[0]}</span>}

        {units.length > 1 && (
          <select
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={({ target }) => setSelectedUnit(target.value)}
          >
            {units.map((unit) => (
              <option value={unit} key={unit}>
                {unit}
              </option>
            ))}
          </select>
        )}
      </div>
    </div>
  );
};

UnitsInput.displayName = "UnitsInput";

export default UnitsInput;
