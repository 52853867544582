import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const ChevronDown = ({ size = 24, color }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 14.086L6.707 8.793a1 1 0 00-1.414 1.414l6 6a1 1 0 001.414 0l6-6a1 1 0 00-1.414-1.414L12 14.086z"
        fill={color || "#494B50"}
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="5" y="8" width="14" height="9">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 14.086L6.707 8.793a1 1 0 00-1.414 1.414l6 6a1 1 0 001.414 0l6-6a1 1 0 00-1.414-1.414L12 14.086z"
          fill={color || "#fff"}
        />
      </mask>
    </svg>
  );
};

export default ChevronDown;
