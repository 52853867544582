/* Text Card
======================================= */

import React from "react";
import { Text, Avatar, Link } from "..";
import JsonContentRenderEngine from "../../JsonContentRenderEngine";

import { VariantTypes } from "../Text/Text";

import styles from "./index.module.scss";
import theme from "../../../theme/default";

/* Props
--------------------------------------- */

interface Props {
  index: number;
  msg?: Message;
  avatar?: any;
  children?: React.ReactNode;
  editMode?: boolean;
  variant?: VariantTypes;
  centerLink?: boolean;
  customStyle?: React.CSSProperties;
}

/* Component
--------------------------------------- */

const TextCard: React.FC<Props> = (props) => {
  const {
    msg,
    children,
    editMode,
    avatar,
    variant: propsVariant,
    centerLink,
    customStyle = {}
  } = props;

  const variant = propsVariant || "large";
  const { index } = props;

  let contentBeforeLink = msg ? msg.payloadContent : children;
  let link;
  let contentAfterLink;
  let linkText;
  // TODO: Make custom card PayloadOptions type
  const payloadOptions = msg?.payloadOptions as BasePayloadOptions;

  const jsonContent = payloadOptions?.contentJson;

  if (msg && payloadOptions) {
    link = payloadOptions.link ? payloadOptions.link : "";
    linkText = payloadOptions.linkText ? payloadOptions.linkText : "";

    if (contentBeforeLink && typeof contentBeforeLink === "string") {
      if (link && contentBeforeLink.includes("[link]")) {
        const splitContent = contentBeforeLink.split("[link]");
        [contentBeforeLink, contentAfterLink] = splitContent;
      }
    }
  }

  const linkStyle: React.CSSProperties = centerLink
    ? { display: "flex", alignItems: "center", justifyContent: "center" }
    : {};
  return (
    <div className={styles.TextCard} style={customStyle}>
      {!editMode && avatar && <Avatar>{avatar}</Avatar>}
      <div style={customStyle}>
        <Text id={`text-${index}`} variant={variant}>
          <>{contentBeforeLink}</>
        </Text>
        {link && (
          <div style={linkStyle}>
            <Link id={`link-${index}`} showIcon={false} textColor={theme.colors.secondary.blue}>
              <a href={link} target="_blank" rel="noopener noreferrer" tabIndex={-9999}>
                {linkText || link}
              </a>
            </Link>
          </div>
        )}
        {contentAfterLink && (
          <Text id={`textContent-${index}`} variant={variant}>
            {contentAfterLink}
          </Text>
        )}

        {jsonContent && <JsonContentRenderEngine content={jsonContent} />}
      </div>
    </div>
  );
};

TextCard.displayName = "TextCard";

export default TextCard;
