/* Text Card
======================================= */

import React, { useState } from "react";
import TextCard from "./TextCard";
import { Input, Button, Text, SkipButton } from "..";
import { Letter, Logo } from "../Icons";
import moment from "moment";
import { ChatCardTypes } from "../../../constants";

/* Props
--------------------------------------- */

interface EmailInputProps {
  msg: Message;
  index: number;
  isCurrentMessage: boolean;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  editMode?: boolean;
  save?: (userResponse: string) => Promise<void>;
}

const isEmail = (value?: string) => {
  return value && value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/gim);
};
/* Component
--------------------------------------- */
const EmailInputCard: React.FC<EmailInputProps> = ({
  msg,
  index,
  isCurrentMessage,
  editMode,
  save,
  fetchNextMessage
}) => {
  const [userResponse, updateResponse] = useState("");

  const submit = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();

    if (save) {
      return save(userResponse);
    }

    return fetchNextMessage({
      lastReceivedMessageId: null,
      userResponse,
      userResponseType: ChatCardTypes.EMAIL_INPUT,
      chatFlowId: null,
      showUserResponse: true,
      createdAt: moment().toDate()
    });
  };
  const isValidEmail = isEmail(userResponse);

  // TODO: Make custom card PayloadOptions type
  const payloadOptions = msg?.payloadOptions as BasePayloadOptions;
  const skippable = payloadOptions?.skippable || false;

  return (
    <form>
      <TextCard
        msg={msg}
        index={index}
        avatar={<Logo size={18} />}
        customStyle={{ marginBottom: "16px" }}
        editMode={editMode}
      />
      {isCurrentMessage && (
        <Input
          name="email"
          type="email"
          placeholder="name@example.com"
          icon={<Letter />}
          maxRows={1}
          value={userResponse}
          label="Enter Email Address"
          maxLength={254}
          updateResponse={updateResponse}
          customStyle={{
            marginBottom:
              isValidEmail || (!isValidEmail && userResponse.length <= 3) ? "30px" : "0px"
          }}
        />
      )}
      {isCurrentMessage && userResponse.length > 3 && !isValidEmail && (
        <Text
          variant="small"
          customStyle={{ marginTop: "6px", marginBottom: "8px", paddingLeft: "10px" }}
          error
        >
          Please enter a valid email.
        </Text>
      )}
      {isCurrentMessage && (
        <Button type="submit" onClick={submit} disabled={!isValidEmail} disableOnClick>
          Send
        </Button>
      )}
      {isCurrentMessage && !editMode && skippable && <SkipButton index={index} message={msg} />}
    </form>
  );
};

export default EmailInputCard;
