/* UserResponseCard
======================================= */

import React from "react";
import { connect } from "react-redux";
import { SelectionContainer, SelectionEntry, ScaleChoice, Text, ImageSelect } from "..";

import { ChatCardTypes } from "../../../constants";

import BookingSummary from "../ChatCards/BookingCard/BookingSummary";
import { isImageSelectResponse } from "../../../types/typeguards";
import { Calendar, CircleCheck, Letter, MapPin, MultipleOn, PaperclipIcon, Phone } from "../Icons";
import { ChatFlowControlFlags } from "../../../types/chats";
import theme from "../../../theme/default";

const {
  TEXT_INPUT,
  NUMBER_INPUT,
  UNITS_INPUT,
  SCALE_INPUT,
  MULTI_SELECT,
  PHONE_INPUT,
  IMAGE_SELECT,
  BOOKING,
  DATE_INPUT,
  ADDRESS_INPUT,
  EMAIL_INPUT,
  PHOTO_UPLOAD,
  INSTRUCTION_REASONS
} = ChatCardTypes;

interface Props {
  msg: Message;
  undoLastAnswer: (messageTemplateId: number) => void;
  canUndo: boolean;
  chatMessagesLoading?: boolean;
}

const UserResponseCard: React.FC<Props> = (props: Props) => {
  const { msg, undoLastAnswer, canUndo, chatMessagesLoading } = props;
  const { payloadContent, userResponseType } = msg;

  let innerContainer: JSX.Element | JSX.Element[];

  if (
    TEXT_INPUT === userResponseType ||
    NUMBER_INPUT === userResponseType ||
    UNITS_INPUT === userResponseType
  ) {
    innerContainer = (
      <SelectionEntry>
        <>{payloadContent}</>
      </SelectionEntry>
    );
  } else if (PHOTO_UPLOAD === userResponseType && typeof payloadContent === "string") {
    innerContainer = (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <PaperclipIcon />
        <Text customStyle={{ paddingLeft: "8px" }}>
          {payloadContent} Attachment{+payloadContent === 1 ? "" : "s"}
        </Text>
      </div>
    );
  } else if (SCALE_INPUT === userResponseType) {
    const scaleInputPayloadContent = payloadContent as ScaleInputOption;
    innerContainer =
      scaleInputPayloadContent.value === ChatFlowControlFlags.SKIP ? (
        <SelectionEntry>{scaleInputPayloadContent.text}</SelectionEntry>
      ) : (
        <ScaleChoice option={(payloadContent as ScaleInputOption) || undefined} isStatic />
      );
  } else if (
    (MULTI_SELECT === userResponseType || INSTRUCTION_REASONS === userResponseType) &&
    Array.isArray(payloadContent)
  ) {
    innerContainer = payloadContent.map((item, index) => {
      const itemLabel = typeof item === "string" ? item : (item as any).text;
      return (
        <SelectionEntry
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          icon={<MultipleOn color={theme.colors.primary.blue} />}
          customStyle={{ marginBottom: "6px" }}
        >
          {itemLabel}
        </SelectionEntry>
      );
    });
  } else if (IMAGE_SELECT === userResponseType && isImageSelectResponse(payloadContent)) {
    const { imageURL, imageSelections } = payloadContent;
    innerContainer = <ImageSelect background={imageURL} selections={imageSelections} isStatic />;
  } else if (BOOKING === userResponseType) {
    innerContainer = <BookingSummary userResponse={payloadContent as BookingUserResponse} />;
  } else {
    let symbol: React.ReactNode = <CircleCheck color={theme.colors.primary.blue} />;
    switch (userResponseType) {
      case PHONE_INPUT:
        symbol = <Phone />;
        break;
      case DATE_INPUT:
        symbol = <Calendar size={22} />;
        break;
      case ADDRESS_INPUT:
        symbol = <MapPin size={24} />;
        break;
      case EMAIL_INPUT:
        symbol = <Letter />;
        break;
      default:
        break;
    }

    if (userResponseType !== "checked") {
      innerContainer = (
        <SelectionEntry overflowWrap={userResponseType === "email" ? "anywhere" : ""} icon={symbol}>
          <>{payloadContent}</>
        </SelectionEntry>
      );
    } else {
      let text = payloadContent;
      if (Array.isArray(payloadContent)) text = payloadContent.join(", ");
      if (!(typeof text === "string")) text = "";

      innerContainer = (
        <SelectionEntry icon={<CircleCheck color={theme.colors.primary.blue} />}>
          {text}
        </SelectionEntry>
      );
    }
  }

  return (
    <SelectionContainer
      canUndo={canUndo && Boolean(msg.messageTemplateId)}
      isDisabled={chatMessagesLoading}
      editClick={() => {
        if (msg.messageTemplateId) undoLastAnswer(msg.messageTemplateId);
      }}
      customStyle={{ marginBottom: "40px" }}
    >
      {innerContainer}
    </SelectionContainer>
  );
};

const mapStateToProps = ({ chatMessages }: ReduxState) => {
  return {
    chatMessagesLoading: chatMessages.loading
  };
};

export default connect(mapStateToProps, null)(UserResponseCard);
