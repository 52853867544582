import React from "react";
import { Text } from "../../ui";

import { HeadingNode } from "../../../types";

type PropsType = {
  node: HeadingNode;
};

const Heading = ({ node }: PropsType) => {
  const { size = "m" } = node.properties;
  const textProps: {
    variant: "medium" | "titleSmall" | "titleMedium";
    bold: boolean;
  } = {
    variant: "titleSmall",
    bold: true
  };

  if (size === "s") {
    textProps.variant = "medium";
    textProps.bold = true;
  }

  if (size === "l") {
    textProps.variant = "titleMedium";
    textProps.bold = true;
  }

  return (
    <Text customStyle={{ marginBottom: "8px" }} {...textProps}>
      {node.properties.value}
    </Text>
  );
};

export default Heading;
