/* Input Field
======================================= */

import React from "react";

import { Text } from "..";
import theme from "../../../theme/default";

/* Style
--------------------------------------- */

const style: React.CSSProperties = {
  textAlign: "center",
  padding: "32px",
  borderBottom: `2px solid ${theme.colors.grey[2]}`
};

/* Props
--------------------------------------- */

interface Props {
  title: string;
  children?: React.ReactNode;
}

/* Component
--------------------------------------- */

const Intro: React.FC<Props> = ({ title, children }) => {
  return (
    <div style={style}>
      <Text variant="titleSmall" bold customStyle={{ marginBottom: "8px" }}>
        {title}
      </Text>
      <Text variant="small" customStyle={{ maxWidth: "311px", margin: "auto" }}>
        {children}
      </Text>
    </div>
  );
};

Intro.displayName = "Intro";

export default Intro;
