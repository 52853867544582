/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import { Link, Button, Drawer } from "../../ui";
import { Scroll } from "../../layout";

import styles from "./index.module.scss";

type PropsType = {
  children: React.ReactNode;
  showInfoModalButton?: boolean;
  label: string;
};

const InfoModal = ({ children, showInfoModalButton = true, label }: PropsType) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Link
        variant="defaultUnbold"
        showIcon={false}
        onClick={() => {
          setOpen(true);
        }}
        customStyle={{ marginBottom: "16px" }}
      >
        {label}
      </Link>
      {open && (
        <Drawer className={styles.InfoModal}>
          <Scroll>
            <header className={styles.InfoModalHeader}>
              <Link variant="back" onClick={() => setOpen(false)}>
                Back
              </Link>
            </header>

            <div className={styles.InfoModalChildren}>
              {children}
              {showInfoModalButton && (
                <Button customStyle={{ marginTop: "32px" }} onClick={() => setOpen(false)}>
                  Ok. Got it!
                </Button>
              )}
            </div>
          </Scroll>
        </Drawer>
      )}
    </>
  );
};

export default InfoModal;
