import React from "react";
import moment from "moment";

import { Text } from "../..";
import { BookingMode } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = { userResponse: BookingUserResponse };

const BookingSummary = ({ userResponse }: PropsType) => {
  const { bookingMode, bookingReason, selectedAppointmentSlots } = userResponse;

  if (bookingMode === BookingMode.REQUEST) return null;

  const isAvailableTimesMode = bookingMode === BookingMode.AVAILABLE_TIMES;
  const isAutoBookMode = bookingMode === BookingMode.AUTO_BOOK;

  return (
    <div>
      {selectedAppointmentSlots && selectedAppointmentSlots.length > 0 ? (
        <>
          {isAvailableTimesMode && (
            <>
              <Text variant="titleMedium" customStyle={{ marginBottom: "24px" }}>
                Appointment request summary
              </Text>
              <Text customStyle={{ marginBottom: "16px" }}>
                These are the dates and times selected for your appointment booking. The clinic will
                review your request and get in contact with you to confirm the date and time.
              </Text>
            </>
          )}
          {isAutoBookMode && (
            <>
              <Text variant="titleMedium" customStyle={{ marginBottom: "24px" }}>
                Booking summary
              </Text>
              <Text customStyle={{ marginBottom: "16px" }}>
                Success! Your appointment has been booked.
              </Text>
            </>
          )}

          <Text variant="meta" customStyle={{ marginBottom: "8px" }}>
            Reason
          </Text>
          <Text customStyle={{ marginBottom: "24px" }}>{bookingReason.label}</Text>
          <Text variant="meta" customStyle={{ marginBottom: "12px" }}>
            Date &#38; Time
          </Text>
          {selectedAppointmentSlots.map((selectedSlot: AvailableSlot) => {
            const appointmentTime = moment(selectedSlot.startTime).format(
              "MMMM Do, YYYY - hh:mm A"
            );
            return (
              <div key={`${selectedSlot.slotIds.join(",")}`} className={styles.Card}>
                <div className={styles.CardInfo}>
                  <Text variant="small" customStyle={{ marginBottom: "4px" }}>
                    {appointmentTime}
                  </Text>
                  <Text variant="small" customStyle={{ marginBottom: "4px" }}>
                    {selectedSlot.locationName}
                  </Text>
                  <Text variant="small" customStyle={{ marginBottom: "4px" }}>
                    {selectedSlot.practitionerName}
                  </Text>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <Text variant="small" customStyle={{ marginBottom: "8px" }}>
          No appointment times selected
        </Text>
      )}
    </div>
  );
};

export default BookingSummary;
