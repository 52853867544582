import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const ChevronUp = ({ size = 24, color }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 10.414l5.293 5.293a1 1 0 001.414-1.414l-6-6a1 1 0 00-1.414 0l-6 6a1 1 0 101.414 1.414L12 10.414z"
        fill={color || "#494B50"}
      />
      <mask id="a" maskUnits="userSpaceOnUse" x="5" y="8" width="14" height="8">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 10.414l5.293 5.293a1 1 0 001.414-1.414l-6-6a1 1 0 00-1.414 0l-6 6a1 1 0 101.414 1.414L12 10.414z"
          fill={color || "#fff"}
        />
      </mask>
    </svg>
  );
};

export default ChevronUp;
