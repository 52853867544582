import React from "react";

import { Card, Text, Steps, Step } from "../ui";
import { Duration, Heading, Important, InfoModalNodeWrapper, Link } from "./Nodes";

import {
  JsonContentNodeTypes,
  JsonContentNode,
  ContentNode,
  HeadingNode,
  TextNode,
  ListNode,
  ListItemNode,
  ImportantNode,
  DurationNode,
  LinkNode,
  InfoModalNode
} from "../../types";

export type JsonContentPlaceholderContext = {
  locationNumber: string;
};

type PropsType = {
  node: JsonContentNode;
  typePath: JsonContentNodeTypes[];
  placeholderContext: JsonContentPlaceholderContext;
  index?: number;
  contentBoxShadow?: boolean;
  contentPadding?: string;
  contentMargin?: string;
  showInfoModalButton?: boolean;
};

const Node = ({
  node,
  index,
  typePath,
  placeholderContext,
  contentBoxShadow,
  contentPadding,
  contentMargin,
  showInfoModalButton
}: PropsType) => {
  if (!node || !node.type) return null;

  // Inject Placeholder values
  if (node.properties) {
    Object.keys(node.properties).forEach((key) => {
      const value = (node.properties as any)[key];
      if (typeof value === "string") {
        (node.properties as any)[key] = value.replace(
          "[locationNumber]",
          placeholderContext.locationNumber
        );
      }
    });
  }

  const childTypePath: JsonContentNodeTypes[] = [...typePath, node.type];
  const infoModalIndex = typePath.indexOf(JsonContentNodeTypes.infoModal);
  const isInfoModal = infoModalIndex >= 0;
  const relevantTypePath = isInfoModal ? typePath.slice(infoModalIndex) : typePath;
  const isNestedList =
    [...relevantTypePath, node.type].filter((type) => type === JsonContentNodeTypes.list).length >
    1;

  if (node.type === JsonContentNodeTypes.heading) {
    const headingNode: HeadingNode = node;
    return <Heading node={headingNode} />;
  }

  if (node.type === JsonContentNodeTypes.text) {
    const textNode: TextNode = node;
    return <Text customStyle={{ marginBottom: "12px" }}>{textNode.properties.value}</Text>;
  }

  if (node.type === JsonContentNodeTypes.important) {
    const importantNode: ImportantNode = node;
    return <Important node={importantNode} />;
  }

  if (node.type === JsonContentNodeTypes.duration) {
    const durationNode: DurationNode = node;
    return <Duration node={durationNode} />;
  }

  if (node.type === JsonContentNodeTypes.link) {
    const linkNode: LinkNode = node;
    return <Link node={linkNode} />;
  }

  if (node.type === JsonContentNodeTypes.content) {
    const contentNode: ContentNode = node;

    return (
      <Card
        boxShadow={contentBoxShadow}
        customStyle={{ padding: contentPadding, margin: contentMargin }}
      >
        {contentNode.children &&
          contentNode.children.map((childNode: JsonContentNode) => {
            return (
              <Node
                key={childNode.id}
                node={childNode}
                typePath={childTypePath}
                placeholderContext={placeholderContext}
                showInfoModalButton={showInfoModalButton}
              />
            );
          })}
      </Card>
    );
  }

  if (node.type === JsonContentNodeTypes.list) {
    const listNode: ListNode = node;
    let variant: any = isInfoModal ? "horizontal" : "vertical";
    if (isNestedList) {
      variant = isInfoModal ? "numbered" : "bullet";
    }

    return (
      <Steps key={listNode.id} variant={variant}>
        {listNode.properties.title && (
          <Text
            variant={isNestedList ? "medium" : "large"}
            customStyle={{ marginBottom: isNestedList ? "8px" : "16px" }}
            bold
          >
            {listNode.properties.title}
          </Text>
        )}
        {listNode.children &&
          listNode.children.map((childNode: JsonContentNode, idx: number) => {
            return (
              <Node
                key={childNode.id}
                node={childNode}
                index={idx}
                typePath={childTypePath}
                placeholderContext={placeholderContext}
              />
            );
          })}
      </Steps>
    );
  }

  if (node.type === JsonContentNodeTypes.listItem) {
    const listItemNode: ListItemNode = node;

    return (
      <Step number={typeof index === "number" ? index + 1 : ""}>
        {listItemNode.children &&
          listItemNode.children.map((childNode: JsonContentNode, idx: number) => {
            return (
              <Node
                key={childNode.id}
                index={idx}
                node={childNode}
                typePath={childTypePath}
                placeholderContext={placeholderContext}
              />
            );
          })}
      </Step>
    );
  }

  if (node.type === JsonContentNodeTypes.infoModal) {
    const infoModalNode: InfoModalNode = node;
    return (
      <InfoModalNodeWrapper
        node={infoModalNode}
        typePath={typePath}
        placeholderContext={placeholderContext}
        showInfoModalButton={showInfoModalButton}
      />
    );
  }

  return null;
};

export default Node;
