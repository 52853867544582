/* Summary Component
======================================= */

import React from "react";
import { Text } from "../../ui";

import styles from "./index.module.scss";

/* Props
--------------------------------------- */

interface Props {
  label?: string;
  children?: React.ReactNode;
  editClick?: (event: React.MouseEvent) => void;
  editable?: boolean;
}

/* Component
--------------------------------------- */

const Summary: React.FC<Props> = (props) => {
  return (
    <div className={styles.Container}>
      <div className={styles.SummaryTop}>
        <Text variant="meta">{props.label}</Text>
        {props.editable && (
          <Text variant="xsmall">
            {props.editClick && (
              <button type="button" onClick={props.editClick}>
                Edit
              </button>
            )}
          </Text>
        )}
      </div>

      <Text>{props.children}</Text>
    </div>
  );
};

Summary.displayName = "Summary";

export default Summary;
