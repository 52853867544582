/* eslint-disable react/jsx-no-useless-fragment */
/* Choice
======================================= */

import React from "react";

import Text from "../Text/Text";
import { Circle, CircleCheck, MultipleOff, MultipleOn } from "../Icons";
import theme from "../../../theme/default";
import styles from "./index.module.scss";

/* Props
--------------------------------------- */

interface Props {
  id?: string;
  children?: React.ReactNode;
  option: string | { text: string; value: string };
  active: boolean;
  selectOption: (
    optionSelectedToDisplay: string,
    optionSelected: string | { text: string; value: string }
  ) => void;
  multiple?: boolean;
  disabled?: boolean;
}

/* Component
--------------------------------------- */

const Choice: React.FC<Props> = (props) => {
  const { id, option, multiple, active, children, selectOption } = props;
  const text = typeof option === "object" ? option.text : option;

  return (
    <button
      id={id}
      key={id}
      type="button"
      className={styles.Choice}
      onClick={() => selectOption(text, option)}
    >
      <span style={{ marginRight: "10px" }}>
        {multiple ? (
          <>
            {active ? (
              <MultipleOn color={theme.colors.primary.blue} />
            ) : (
              <MultipleOff color={theme.colors.grey[2]} />
            )}
          </>
        ) : (
          <>
            {active ? (
              <CircleCheck color={theme.colors.primary.blue} />
            ) : (
              <Circle color={theme.colors.grey[2]} />
            )}
          </>
        )}
      </span>
      <div>
        {text && <Text as="span">{text}</Text>}
        {children && children}
      </div>
    </button>
  );
};

Choice.displayName = "Choice";

export default Choice;
