/* Important
======================================= */

import React from "react";

import { Text } from "../../ui";
import { Box } from "../../layout";
import theme from "../../../theme/default";
import { ImportantIcon } from "../Icons";

import styles from "./index.module.scss";

interface Props {
  title?: string;
  backgroundColor?: string;
  borderRadius?: "none" | "normal" | "full";
  children?: React.ReactNode;
}

const Important: React.FC<Props> = ({
  backgroundColor = theme.colors.error.background,
  borderRadius = theme.radii.normal,
  title,
  children
}) => {
  const style: React.CSSProperties = {
    backgroundColor,
    borderRadius
  };

  return (
    <article style={style} className={styles.Container}>
      <div style={{ marginRight: "3px", marginTop: "-1px" }}>
        <ImportantIcon color={theme.colors.error.text} />
      </div>
      <Box customStyle={{ paddingTop: "2px" }}>
        {title && (
          <Text variant="small" customStyle={{ marginBottom: "3px" }} bold uppercase error>
            {title}
          </Text>
        )}
        <Text variant="small" error>
          {children}
        </Text>
      </Box>
    </article>
  );
};

Important.displayName = "Important";

export default Important;
