/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* Image Select
======================================= */

import React, { useRef } from "react";
import cx from "classnames";

import styles from "./index.module.scss";

/* Props
--------------------------------------- */
interface Props {
  background: string;
  debug?: boolean;
  isStatic?: boolean;
  zones?: Zone[];
  selections?: {
    x: string;
    y: string;
  }[];
  customStyle?: React.CSSProperties;
  handleTouch?: (touch: ImageTouch) => void;
}

/* Component
--------------------------------------- */

const ImageSelect: React.FC<Props> = ({
  debug = false,
  isStatic = false,
  zones = [],
  selections = [],
  handleTouch = undefined,
  background,
  customStyle = {},
  ...otherProps
}: Props) => {
  const props = {
    debug,
    isStatic,
    zones,
    selections,
    handleTouch,
    ...otherProps
  };
  const boundingRef = useRef<HTMLDivElement>(null);

  const handleClick = (event: React.MouseEvent, zone: Zone) => {
    if (props.handleTouch) {
      const parent = boundingRef.current;
      const bounds = parent?.getBoundingClientRect();
      const circleOffsetX = 10.09 / 2;
      const circleOffsetY = 9.52 / 2;

      if (parent && bounds) {
        const posX = event.clientX - bounds.left;
        const posY = event.clientY - bounds.top;

        const percentX = (100 * posX) / bounds.width;
        const percentY = (100 * posY) / bounds.height;

        const x = `${percentX - circleOffsetX}%`;
        const y = `${percentY - circleOffsetY}%`;

        props.handleTouch({ x, y, zone });
      }
    }
  };

  const zoneItems = zones?.map((zone) => (
    <div
      className={styles.zone}
      key={zone.id + zone.x + zone.y}
      style={{ width: zone.w, height: zone.h, top: zone.y, left: zone.x }}
      onClick={(event) => handleClick(event, zone)}
    />
  ));

  const selectionItems = selections?.map((entry, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <div className={styles.selection} key={index} style={{ top: entry.y, left: entry.x }} />
  ));

  return (
    <div
      ref={boundingRef}
      style={customStyle}
      className={cx(styles.Container, { [styles.isDebug]: debug, [styles.isStatic]: isStatic })}
    >
      <div className={styles.zones}>{zoneItems}</div>
      <div className={styles.selections}>{selectionItems}</div>
      <img src={background} alt="" />
    </div>
  );
};

ImageSelect.displayName = "ImageSelect";

export default ImageSelect;
