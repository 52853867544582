import icons, { IconTypes } from "../theme/default/icons";
import cigIcons, { IconTypes as CigIconTypes } from "../theme/cig/icons";

const themeMap = {
  default: icons,
  cig: cigIcons
};

export const get = (obj: any, path: any, def = "") => {
  const fullPath = path.replace(/\[/g, ".").replace(/]/g, "").split(".").filter(Boolean);

  return fullPath.every(everyFunc) ? obj : def;

  function everyFunc(step: string) {
    // eslint-disable-next-line no-return-assign
    return !(step && (obj = obj[step]) === undefined);
  }
};

export const getThemeIcons = (themeName: ThemeName): any => {
  return themeMap[themeName];
};

export type AllThemeIconTypes = IconTypes | CigIconTypes;
