/* Date Input Card
======================================= */

import React from "react";
import TextCard from "./TextCard";
import { DateInput, Button, SkipButton, Text } from "..";
import moment from "moment";
import { ChatCardTypes } from "../../../constants";
import { Logo } from "../Icons";
import { userInputHasEnoughDigits, isValidDateFormat } from "../../../utils/validators/isValidDate";

/* Props
--------------------------------------- */

interface DateInputProps {
  msg: Message;
  index: number;
  isCurrentMessage: boolean;
  fetchNextMessage: (params: FetchNextMessageParams) => void;
  editMode?: boolean;
  save?: (userResponse: string) => Promise<void>;
}

interface TextInputState {
  userResponse: string;
}

/* Component
--------------------------------------- */

class DateInputCard extends React.Component<DateInputProps, TextInputState> {
  constructor(props: DateInputProps) {
    super(props);

    this.state = {
      userResponse: ""
    };
  }

  updateResponse = (userResponse: string, finishedInputLength: number) => {
    // react-mask-input allows an extra character to be input after the mask is full!

    if (userResponse.replace(/\D/g, "").length < finishedInputLength + 1) {
      userResponse = userResponse.substring(0, userResponse.length);
      this.setState({ userResponse });
    }
  };

  getDatePlaceholder = () => {
    const { msg } = this.props;
    // TODO: Make custom card PayloadOptions type
    const payloadOptions = msg?.payloadOptions as BasePayloadOptions;
    return payloadOptions?.format || "YYYY-MM-DD";
  };

  submit = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    const { userResponse } = this.state;
    const { fetchNextMessage, save } = this.props;

    if (save) {
      return save(userResponse);
    }

    return fetchNextMessage({
      lastReceivedMessageId: null,
      userResponse: userResponse.replace(/YMD/g, ""),
      userResponseType: ChatCardTypes.DATE_INPUT,
      chatFlowId: null,
      showUserResponse: true,
      createdAt: moment().toDate()
    });
  };

  render() {
    const { msg, index, isCurrentMessage, editMode } = this.props;
    const { userResponse } = this.state;
    const { payloadContent } = msg;

    // TODO: Make custom card PayloadOptions type
    const payloadOptions = msg?.payloadOptions as BasePayloadOptions;

    const skippable = payloadOptions?.skippable || false;
    const placeHolder: "YYYY-MM-DD" | "YYYY-MM" | "YYYY" | "DD-MM-YYYY" = this.getDatePlaceholder();
    const mask = placeHolder.replace(/[YMD]/g, "9");

    const dateName =
      placeHolder?.length === 10 // eslint-disable-line no-nested-ternary
        ? "date_full"
        : placeHolder?.length === 7
          ? "date_month"
          : "date_year";
    const finishedInputLength = placeHolder?.length === 10 ? 8 : placeHolder?.length === 7 ? 6 : 4; // eslint-disable-line no-nested-ternary

    const isBadDate =
      !userInputHasEnoughDigits(userResponse, this.getDatePlaceholder()) ||
      !isValidDateFormat(userResponse, this.getDatePlaceholder());

    return (
      <form>
        <TextCard
          msg={msg}
          index={index}
          avatar={<Logo size={18} />}
          customStyle={{ marginBottom: "16px" }}
          editMode={editMode}
        >
          <>{payloadContent}</>
        </TextCard>
        {isCurrentMessage && (
          <DateInput
            id={`dateInput-${index}`}
            placeholder={placeHolder}
            name={dateName}
            mask={mask}
            label="Enter a Date"
            customStyle={{ marginBottom: "30px" }}
            updateResponse={(response) => this.updateResponse(response, finishedInputLength)}
          />
        )}
        {isCurrentMessage && !isValidDateFormat(userResponse, this.getDatePlaceholder()) && (
          <Text
            variant="small"
            customStyle={{
              transform: "translate(0,-20px)",
              paddingLeft: "10px"
            }}
            error
          >
            Please enter a valid date.
          </Text>
        )}
        {isCurrentMessage && (
          <Button
            id={`sendBtn-${index}`}
            type="submit"
            onClick={this.submit}
            disabled={!userResponse || isBadDate}
            disableOnClick
          >
            Send
          </Button>
        )}
        {isCurrentMessage && !editMode && skippable && <SkipButton index={index} message={msg} />}
      </form>
    );
  }
}

export default DateInputCard;
