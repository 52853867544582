/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import moment from "moment";
import cx from "classnames";
import { connect } from "react-redux";

import { Box } from "../../../layout";
import { Button, Drawer, Text, Modal } from "../..";

import { BookingMode } from "../../../../types";
import { ChevronDown, ChevronUp, DeleteIcon, ManStanding } from "../../Icons";

import styles from "./index.module.scss";
import theme from "../../../../theme/default";

interface PropsType {
  bookingMode: BookingMode;
  bookingReasonLabel: string;
  selectedAppointmentSlots?: Array<AvailableSlot>;
  removeAvailableSlotSelection: (slot: AvailableSlot) => void;
  setSlotSelection: (slots: AvailableSlot[]) => void;
  submitSelectedSlots: (submitAsBookingMode?: BookingMode) => void;
  reviewOpen: boolean;
  setReviewOpen: (isOpen: boolean) => void;
  autoBook: () => void;
  autoBookLoading: boolean;
  failedAttempts: number;
}

const ReviewDrawer = ({
  bookingMode,
  bookingReasonLabel,
  selectedAppointmentSlots,
  submitSelectedSlots,
  removeAvailableSlotSelection,
  setSlotSelection,
  reviewOpen,
  setReviewOpen,
  autoBook,
  autoBookLoading,
  failedAttempts
}: PropsType) => {
  const [initialFailedAttempts, setInitialFailedAttempts] = useState(failedAttempts);
  const [failureModalShowing, setFailureModalShowing] = useState(false);

  const sortedSelectedSlots = selectedAppointmentSlots?.sort((a, b) => {
    if (a.startTime > b.startTime) return 1;
    if (a.startTime < b.startTime) return -1;
    return 0;
  });
  const hasSelectedTimes = selectedAppointmentSlots && selectedAppointmentSlots.length > 0;

  useEffect(() => {
    if (failedAttempts >= 3) {
      setFailureModalShowing(true);
    }
  }, [failedAttempts]);

  if (bookingMode === BookingMode.AUTO_BOOK)
    return (
      <Drawer className={styles.ReviewDrawer}>
        {initialFailedAttempts === failedAttempts ? (
          <>
            <div
              className={cx(styles.ReviewBackground, { [styles.Show]: reviewOpen })}
              onClick={() => setReviewOpen(false)}
            />
            {hasSelectedTimes ? (
              <div
                className={cx(styles.ReviewContainer, { [styles.ReviewContainerOpen]: reviewOpen })}
              >
                <div className={styles.ReviewHeader} onClick={() => setReviewOpen(!reviewOpen)}>
                  {reviewOpen ? (
                    <Text variant="titleMedium" customStyle={{ marginRight: "12px" }}>
                      Review and book
                    </Text>
                  ) : (
                    <Text
                      customStyle={{ marginRight: "12px" }}
                      textColor={reviewOpen ? "default" : "white"}
                      bold={!reviewOpen}
                    >
                      Review and book
                    </Text>
                  )}
                  {reviewOpen ? (
                    <ChevronDown size={18} color={theme.colors.grey[4]} />
                  ) : (
                    <ChevronUp size={18} color={theme.colors.white} />
                  )}
                </div>

                <Box className={styles.ReviewContent}>
                  {sortedSelectedSlots && sortedSelectedSlots.length > 0 && (
                    <>
                      <Text variant="meta" customStyle={{ marginBottom: "12px" }}>
                        Reason
                      </Text>
                      <Text customStyle={{ marginBottom: "16px" }}>{bookingReasonLabel}</Text>
                      <Text variant="meta" customStyle={{ marginBottom: "12px" }}>
                        Date &#38; Time Selected
                      </Text>
                      {sortedSelectedSlots.map((selectedSlot: AvailableSlot) => {
                        const appointmentTime = moment
                          .tz(selectedSlot.startTime, selectedSlot.locationTimezone)
                          .format("MMMM Do, YYYY - hh:mm A");
                        return (
                          <div key={`${selectedSlot.slotIds.join(",")}`} className={styles.Card}>
                            <div className={styles.CardInfo}>
                              <Text variant="small" customStyle={{ marginBottom: "4px" }}>
                                {appointmentTime}
                              </Text>
                              <Text variant="small" customStyle={{ marginBottom: "4px" }}>
                                {selectedSlot.locationName}
                              </Text>
                              <Text variant="small" customStyle={{ marginBottom: "4px" }}>
                                {selectedSlot.practitionerName}
                              </Text>
                            </div>

                            <button
                              type="button"
                              onClick={() => {
                                removeAvailableSlotSelection(selectedSlot);
                                setReviewOpen(false);
                              }}
                            >
                              <DeleteIcon color={theme.colors.icon.primary} />
                            </button>
                          </div>
                        );
                      })}
                    </>
                  )}
                  <Button
                    id="bookingSubmit"
                    disabled={autoBookLoading}
                    onClick={autoBook}
                    customStyle={{ marginBottom: "16px", marginTop: "16px" }}
                  >
                    Book now
                  </Button>
                </Box>
              </div>
            ) : (
              <div
                className={cx(styles.ReviewContainer, { [styles.ReviewContainerOpen]: reviewOpen })}
              >
                {reviewOpen && (
                  <>
                    <div className={styles.ReviewHeader} onClick={() => setReviewOpen(!reviewOpen)}>
                      <Text variant="titleMedium" customStyle={{ marginRight: "12px" }}>
                        No times work
                      </Text>
                    </div>
                    <Box className={styles.ReviewContent}>
                      <Text customStyle={{ marginBottom: "32px" }}>
                        If you can’t find a time that works you can still submit an appointment
                        request and the clinic will call you
                      </Text>
                      <Button
                        id="bookingSubmit"
                        disabled={autoBookLoading}
                        onClick={() => {
                          submitSelectedSlots(BookingMode.REQUEST);
                        }}
                        customStyle={{ marginBottom: "16px", marginTop: "16px" }}
                      >
                        Create a request
                      </Button>
                    </Box>
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <div className={cx(styles.ReviewBackground, { [styles.Show]: reviewOpen })} />
            <div
              className={cx(styles.ReviewContainer, { [styles.ReviewContainerOpen]: reviewOpen })}
            >
              <div className={styles.reviewHeader}>
                <Text variant="titleMedium" customStyle={{ marginRight: "12px" }}>
                  Oops! Unable to book
                </Text>
              </div>
              <Box className={styles.ReviewContent}>
                <Text customStyle={{ marginBottom: "16px" }}>
                  Please try again or select another time.
                </Text>

                <Button
                  id="bookingRetry"
                  onClick={() => {
                    setReviewOpen(false);
                    setInitialFailedAttempts(failedAttempts);
                  }}
                  variant={hasSelectedTimes ? undefined : "secondary"}
                  customStyle={{ marginBottom: "16px", marginTop: "16px" }}
                >
                  Try again
                </Button>
              </Box>
            </div>
            <Modal showing={failureModalShowing} setShowing={setFailureModalShowing}>
              <div className={styles.FailureModalContent}>
                <ManStanding />
                <Text variant="titleMedium" customStyle={{ marginBottom: "16px" }}>
                  Something&#39;s not working
                </Text>
                <Text customStyle={{ marginBottom: "48px" }}>
                  You can still create an appointment request for the clinic to review.
                </Text>
              </div>

              <Button
                onClick={() => {
                  setSlotSelection([]);
                  submitSelectedSlots(BookingMode.REQUEST);
                }}
                customStyle={{ marginBottom: "64px" }}
              >
                Create a request
              </Button>
            </Modal>
          </>
        )}
      </Drawer>
    );

  return (
    <Drawer className={styles.ReviewDrawer}>
      <div
        className={cx(styles.ReviewBackground, { [styles.Show]: reviewOpen })}
        onClick={() => setReviewOpen(false)}
      />
      <div className={cx(styles.ReviewContainer, { [styles.ReviewContainerOpen]: reviewOpen })}>
        <div className={styles.ReviewHeader} onClick={() => setReviewOpen(!reviewOpen)}>
          {reviewOpen ? (
            <Text variant="titleMedium" customStyle={{ marginRight: "12px" }}>
              {hasSelectedTimes ? "Review selected times" : "No times selected"}
            </Text>
          ) : (
            <Text
              customStyle={{ marginRight: "12px" }}
              textColor={reviewOpen ? "default" : "white"}
              bold={!reviewOpen}
            >
              {hasSelectedTimes
                ? `Review ${sortedSelectedSlots?.length || "0"} selected`
                : "No times selected"}
            </Text>
          )}
          {reviewOpen ? (
            <ChevronDown size={18} color={theme.colors.grey[4]} />
          ) : (
            <ChevronUp size={18} color={theme.colors.white} />
          )}
        </div>
        <Box className={styles.ReviewContent}>
          {hasSelectedTimes && sortedSelectedSlots ? (
            <>
              <Text customStyle={{ marginBottom: "16px" }}>
                These are the dates and times selected for your appointment booking. The clinic will
                review your request and get in contact with you to confirm the date and time.
              </Text>
              <Text variant="meta" customStyle={{ marginBottom: "12px" }}>
                Reason
              </Text>
              <Text customStyle={{ marginBottom: "16px" }}>{bookingReasonLabel}</Text>
              <Text variant="meta" customStyle={{ marginBottom: "12px" }}>
                Date &#38; Time Selected
              </Text>
              {sortedSelectedSlots.length === 0 && (
                <div className={styles.Card}>
                  <div className={styles.CardInfo}>
                    <Text variant="small" customStyle={{ marginBottom: "4px" }}>
                      No appointment times selected
                    </Text>
                  </div>
                </div>
              )}
              {sortedSelectedSlots.map((selectedSlot: AvailableSlot) => {
                const appointmentTime = moment
                  .tz(selectedSlot.startTime, selectedSlot.locationTimezone)
                  .format("MMMM Do, YYYY - hh:mm A");
                return (
                  <div key={`${selectedSlot.slotIds.join(",")}`} className={styles.Card}>
                    <div className={styles.CardInfo}>
                      <Text variant="small" customStyle={{ marginBottom: "4px" }}>
                        {appointmentTime}
                      </Text>
                      <Text variant="small" customStyle={{ marginBottom: "4px" }}>
                        {selectedSlot.locationName}
                      </Text>
                      <Text variant="small" customStyle={{ marginBottom: "4px" }}>
                        {selectedSlot.practitionerName}
                      </Text>
                    </div>

                    <button
                      type="button"
                      onClick={() => {
                        removeAvailableSlotSelection(selectedSlot);
                      }}
                    >
                      <DeleteIcon color={theme.colors.icon.primary} />
                    </button>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <Text customStyle={{ marginBottom: "16px" }}>
                The times you select will show up here.
              </Text>
              <Text customStyle={{ marginBottom: "32px" }}>
                If you can’t find a time that works you can still submit an appointment request and
                the clinic will call you
              </Text>
            </>
          )}
          <Button
            id="bookingSubmit"
            onClick={() => {
              const submitMode = hasSelectedTimes
                ? BookingMode.AVAILABLE_TIMES
                : BookingMode.REQUEST;
              submitSelectedSlots(submitMode);
            }}
            variant={hasSelectedTimes ? undefined : "secondary"}
            customStyle={{ marginBottom: "16px", marginTop: "16px" }}
          >
            {hasSelectedTimes ? "Send Request" : "Submit request with no times"}
          </Button>
        </Box>
      </div>
    </Drawer>
  );
};

function mapStateToProps({ booking }: ReduxState) {
  return {
    failedAttempts: booking.failedAttempts,
    autoBookLoading: booking.autoBookLoading
  };
}

export default connect(mapStateToProps)(ReviewDrawer);
