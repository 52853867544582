/* Date Input
======================================= */

import React, { useImperativeHandle } from "react";
import { useMask } from "@react-input/mask";

import { Input } from "../../../ui";
import { Calendar } from "../../Icons";

/* Props
--------------------------------------- */

interface Props {
  id?: string;
  name?: string;
  mask: string;
  placeholder?: string;
  label?: string;
  customStyle: React.CSSProperties;
  inputRef?: any; // React.RefObject<HTMLInputElement | HTMLTextAreaElement>
  updateResponse?: (userResponse: string) => void;
}

/* Component
--------------------------------------- */

const DateInput: React.FC<Props> = ({
  name = "",
  inputRef = null,
  customStyle = {},
  id,
  ...otherProps
}) => {
  const props = { name, inputRef, ...otherProps };
  const maskRef = useMask({
    mask: props.placeholder,
    replacement: { Y: /\d/, M: /\d/, D: /\d/ },
    showMask: true
  });

  useImperativeHandle(inputRef, () => maskRef.current);
  return (
    <div style={customStyle}>
      <Input
        id={id}
        inputRef={maskRef}
        name={props.name}
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        updateResponse={props.updateResponse}
        maxRows={1}
        icon={<Calendar size={22} />}
        placeholder={props.placeholder}
        label={props.label}
      />
    </div>
  );
};

DateInput.displayName = "DateInput";

export default DateInput;
